
import { purple, yellow } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {

    '& .activity-type.warning': {
      backgroundColor: theme.palette.error.dark,
      color: purple[100],

      fontWeight: '600',
    },
    '& .warning': {
      backgroundColor: theme.palette.warning.light,
      color: '#1a3e72',
    },
  },

}));
