import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useStyles } from "./styles";
import { useHistory, useParams } from "react-router-dom";
import TaxRatesForm from "../Form";
import useTaxRatesContext from "../../../../hooks/useTaxRatesContext";
import { useEnqueueSnackbar } from "../../../../hooks/useEnqueueSnackbar";

const CreateEditTaxRates = ({ onUpdate, taxesWrite }) => {
  const enqueueSnackbar = useEnqueueSnackbar();
  const history = useHistory();
  const { taxRateID } = useParams();
  const [taxRate, setTaxRate] = useState({
    taxRateID,
    description: "",
    taxAmount: 0
  });
  const { getTaxRate } = useTaxRatesContext();

  useEffect(() => {
    if (taxRateID != -1) {
      getTaxRatesInfo();
    }
  }, []);

  const getTaxRatesInfo = () => {
    getTaxRate(taxRateID)
      .then((res) => {
        if (res.status === 200) {
          setTaxRate(res.data);
        } else {
          enqueueSnackbar(
            "We encountered a problem retrieving the tax rates information",
            { variant: "error", tag: "FailedToRetrieveTaxRates", }
          );
        }
      })
      .catch(() => {
        enqueueSnackbar("Failed to retrieve tax rate info", { variant: "error", tag: "FailedToRetrieveTaxRateInfo", });
      });
  };

  const handleCancel = () => {
    history.goBack();
  };

  const handleOperationComplete = () => {
    onUpdate();
    history.goBack();
  };

  return (
    <TaxRatesForm
      data={taxRate}
      onCancel={handleCancel}
      onSubmitComplete={handleOperationComplete}
      onDeleteComplete={handleOperationComplete}
      taxesWrite={taxesWrite}
    />
  );
};

CreateEditTaxRates.defaultProps = {
  onUpdate: () => {},
};

CreateEditTaxRates.propTypes = {
  onUpdate: PropTypes.func,
};

export default CreateEditTaxRates;
