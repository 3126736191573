import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    root: {},

    startingAmountContainer: {
      margin: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        paddingRight: theme.spacing(4)
      },      
    },
    startingAmountHeader: {
        marginBottom: theme.spacing(1)
    }
  }));
  