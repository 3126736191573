import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import { TextField, Button, Switch } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { useStyles } from "./styles";
import useSettingsContext from "../../../hooks/useSettingsContext";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { devicemodeslist } from '../../../constants/SettingsStrings';
import useCurrentFacility from "../../../hooks/useCurrentFacility";

const SettingDisplay = ({ setting, setAlert, onSettingSaved }) => {
  const classes = useStyles();
  const [inputVal, setInputVal] = React.useState();
  const { facilityID } = useCurrentFacility();
  const { updateSetting } = useSettingsContext();

  useEffect(() => {
    setInputVal(setting.settingValue);
  }, [setting]);

  const handleSaveClick = async () => {
    try {
      const result = await updateSetting(facilityID, {
        name: setting.settingName,
        value: inputVal,
      });
      if (result.status === 200) {
        setAlert({
          isOpen: true,
          message: "Successfully saved setting",
          type: "success",
        });
        onSettingSaved({
          settingName: setting.settingName,
          settingValue: inputVal,
          validationType: setting.validationType,
        });
      } else {
        setAlert({
          isOpen: true,
          message: "Failed to save setting",
          type: "error",
        });
      }
    } catch (err) {
      setAlert({
        isOpen: true,
        message: "Failed to save setting",
        type: "error",
      });
    }
  };

  const renderSwitch = (setting) => {
    if (inputVal === "true" || inputVal === "false") {
      if (inputVal === "true") setInputVal(true);
      if (inputVal === "false") setInputVal(false);
    }
    return (
      <>
        <Switch          
          name={setting.settingName}
          checked={inputVal}
          onChange={(e) => setInputVal(e.target.checked)}
        />
        {setting.settingName}
        {renderSaveBtn()}
      </>
    );
  };

  const renderTextInput = (setting) => {
    return (
      <>
        <TextField
          variant="outlined"
          name={setting.settingName}
          label={setting.settingName}
          value={inputVal}
          onChange={(e) => setInputVal(e.target.value)}
        />
        {renderSaveBtn()}
      </>
    );
  };

  const renderNumberInput = (setting) => {
    return (
      <>
        <TextField
          name={setting.settingName}
          variant="outlined"
          label={setting.settingName}
          value={inputVal}
          onChange={(e) => setInputVal(e.target.value)}
        />
        {renderSaveBtn()}
      </>
    );
  };

  const renderDateTime = (setting) => {
    return (
      <>
        <KeyboardDateTimePicker
          inputVariant="outlined"
          label={setting.settingName}
          format="MM/DD/YYYY  hh:mm A"
          mask="__/__/____  __:__ _M"
          onChange={(val) => setInputVal(val)}
        />
        {renderSaveBtn()}
      </>
    );
  };

  const renderDropDown = (setting, optionsArr) => {
    return (
      <>
        <FormControl>
          <InputLabel>{setting.settingName}</InputLabel>
          <Select
            native
            value={inputVal}
            onChange={(e) => setInputVal(e.target.value)}
          >
            {optionsArr.map((name) => (
              <option key={name} value={name}>{name}</option>
            ))}
          </Select>
        </FormControl>
        {renderSaveBtn()}
      </>
    );
  };

  const renderSaveBtn = () => {
    return (
      <Button
        className={classes.saveBtn}
        startIcon={<SaveIcon />}
        color="primary"
        variant="contained"
        onClick={handleSaveClick}
      >
        Save
      </Button>
    );
  };

  const getSettingField = (setting) => {
    switch (setting.validationType) {
      case "noOp":
        return renderTextInput(setting);
      case "notNull": {
        var low = setting.settingName.toLowerCase();
        if (low === "setting.devicemode")
          return renderDropDown(setting, devicemodeslist);
        else
          return renderTextInput(setting);
      }
      case "number":
      case "decimal":
        return renderNumberInput(setting);
      case "dateTime":
        return renderDateTime(setting);
      case "bool":
        return renderSwitch(setting);
      default:
        return React.Fragment;
    }
  };

  return <div className={classes.root}>{getSettingField(setting)}</div>;
};

SettingDisplay.defaultProps = {
  setting: { settingName: "", settingValue: "", validatonType: "" },
  setAlert: () => {},
  onSettingSaved: () => {},
};

SettingDisplay.propTypes = {
  setting: PropTypes.shape({
    settingName: PropTypes.string,
    settingValue: PropTypes.string,
    validationType: PropTypes.string,
  }),
  setAlert: PropTypes.func,
  onSettingSaved: PropTypes.func,
};

export default SettingDisplay;
