import React from "react";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import NavListItems from "./NavListItems";
import { useStyles } from "./styles";
import { linksArray } from "../SideDrawer/NavListItems/NavListArray";
import newLogo from "../../assets/logos/a1-logo-horizontal-light-cropped.svg";
import { WarningBanner } from "../WarningBanner";
const SideDrawer = (props) => {
  const classes = useStyles(props);
  return (
    <Drawer
      variant={props.mobile ? "temporary" : "permanent"}
      classes={{
        paper: clsx(
          classes.drawerPaper,
          !props.isOpen && classes.drawerPaperClose
        ),
      }}
      className={clsx("amano-app-drawer")}
      open={props.isOpen}
      onClose={props.onDrawerCloseRequested}
    >
      <div className={classes.toolbarIcon}>
        <img
          width="170"
          style={{ marginTop: -4, marginRight: 8 }}
          src={newLogo}
          alt={"logo"}
        ></img>
        <IconButton
          onClick={props.onDrawerCloseRequested}
          className={clsx("close-app-drawer")}
        >
          <ChevronLeftIcon className={classes.menuCloseIcon} />
        </IconButton>
      </div>
      <Divider />
      <div className={classes.menuWrapper}>
        <List component="nav">
          <NavListItems linksArray={linksArray} />
        </List>
        <WarningBanner></WarningBanner>
      </div>
    </Drawer>
  );
};

export default SideDrawer;
