import React from "react";
import { useStyles } from "./styles";
import { Grid, InputAdornment, TextField, Typography } from "@material-ui/core";
import { Controller } from "react-hook-form";
import clsx from "clsx";

const ShiftSessionStartingAmount = ({
    control,
    errors
  }) => {
  const classes = useStyles();
  return (
    <div className={clsx("card-content", classes.contentRoot)}>
      <Grid container item xs={12} lg={6} className={clsx("starting-amount", classes.startingAmountContainer)}>
        <Grid item xs={12} >
          <Typography className={clsx(classes.startingAmountHeader)} variant="h5">
            Starting Dollar Amount
          </Typography>
          <Controller
            name="startingamount"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                label="Starting Dollar Amount"
                {...field}
                error={!!errors.startingamount}
                helperText={errors.startingamount && errors.startingamount.message}
                type="number"
                variant="outlined"
                className={clsx("startingamount")}
                InputProps={{
                  inputProps: {
                    min: 0,
                    step: 0.01,
                    "data-testid": "startingamount",
                    "aria-label": "startingamount",
                    inputMode: "numeric",
                    role: "textbox",
                    pattern: "[0-9]*"
                  },
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ShiftSessionStartingAmount;