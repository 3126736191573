import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {},

  listItemDiv: {
    width: "100%",
    overflow: "auto",
  },
  cashieredDeviceContainer: {
    margin: theme.spacing(2)
  },
  cashieredDeviceDropdown: {
    marginTop: theme.spacing(1),
  },
}));
