import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  btnGroup: {
    textAlign: "right",
    "& > *": {
      minWidth: "120px",
      margin: theme.spacing(2),
    },
  },
  ticketfieldDividerFixed: {
    marginBottom: '15px',
    marginLeft: 'auto',
    width: '480px',
  },
  ticketfieldcontainer: {
    marginLeft: '10px',
    marginRight: '10px',
  },
  TciektFieldsDrawer: {
    display: "inline-block",
    [theme.breakpoints.up('sm')]: {
      width: "500px",
      flexShrink: 0,
    },
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      flexShrink: 0,
    },
  },
}));
