import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  TicketAutoValidationRow: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    flex: "0 1 100%",
  },
  ticketInfo: {
    maxHeight: "60px",
    margin: "0px",
  },
  ticketIdText: {},
  validationDescriptionContainer: {
    width: "100%",
    display: "inline-flex",
    justifyContent: "space-between",
  },
  appliedValidation: {
    maxWidth: "75%",
  },
}));