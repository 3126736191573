import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    margin: "auto",
    minHeight: "350px",
    padding: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      width: "75%",
    },
  },
  noMargin: { margin: "unset" },
  stretch: {
    width: "100%",
  },
  ticketStatus: {
    marginLeft: "auto",
    display: "flex",
  },
  ticketBack: {
    fontSize: 20,
    color: theme.palette.primary.main,
  },
  statusTitle: {
    paddingRight: "20px",
  },
  vertCenter: {
    margin: "auto",
  },
  card: {
    minWidth: 275,
  },
  editIcon: {
    position: "absolute",
  },
  labelLeft: {
    marginLeft: 12,
  },
  centered: {
    textAlign: "center",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  goUpper: {
    textTransform: "uppercase",
  },
  labelTitle: {
    fontSize: 14,
    textTransform: "uppercase",
  },
  title: {
    fontSize: 18,
  },
  pos: {
    marginBottom: 12,
  },
  backButton: {
    width: "10%",
  },
  formButton: {
    margin: theme.spacing(2, 2),
  },
  active: {
    color: theme.palette.success.dark,
  },
  heading: {
    marginBottom: 20,
    width: "100%",
  },
  datesContainer: {
    marginTop: theme.spacing(6),
  },
  alert: {
    margin: theme.spacing(3),
  },
  fieldHeading: {
    margin: theme.spacing(2),
    fontWeight: 550,
  },
  flipit: {
    color: "#fff",
    marginTop: 30,
  },
  statusSelector: {
    width: "200px",
    marginLeft: "auto",
  },
}));
