import React from "react";
import { useStyles } from "./styles";
import { Grid,  TextField, Typography } from "@material-ui/core";
import {  Controller } from "react-hook-form";
import clsx from "clsx";
import MenuItem from "@material-ui/core/MenuItem";

const CashieredDeviceSelection = ({
    availableCashieredDevices,
    currentSelectedDevice,
    control,
    errors
  }) => {

    const classes = useStyles();
  return (
    <div className={clsx("card-content", classes.contentRoot)}>
      <Grid item xs={12} lg={6} className={clsx(classes.cashieredDeviceContainer)}>
        <Typography className={clsx("cashiered-device-selection")} variant="h5">
          Choose Your Cashiered Device
        </Typography>
        <Controller
          name="cashiereddevice"
          control={control}
          render={({ field }) => (
          <TextField
            label="Cashiered Device"
            fullWidth
            {...field}
            defaultValue={currentSelectedDevice}
            error={!!errors.cashiereddevice}
            helperText={errors.cashiereddevice && "Required"}
            id="cashiereddevice"
            className={clsx(
              "cashiereddevice",
              classes.cashieredDeviceDropdown
            )}
            select
            variant="outlined"
            SelectProps={{
              SelectDisplayProps: {
                "data-testid": "cashiered-device-selection",
                "data-options": availableCashieredDevices?.map(device => device.name),
                "aria-label": "cashiered-device-dropdown"
              },
            }}
          >
            {availableCashieredDevices?.map((device) => (
              <MenuItem key={device.deviceID} value={device.deviceID} data-name={device.name} data-testid="device-option">
                {device.name}
              </MenuItem>
            ))}
          </TextField>
        )}
      />
      </Grid>
    </div>    
  );
};

export default CashieredDeviceSelection;