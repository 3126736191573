import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  cardContent: {
    width: "100%",
    paddingLeft: theme.spacing(3),
  },
  ThirdPartyVendorTitle: {
    color: theme.palette.white?.main,
    fontWeight: "bold",
    paddingTop: theme.spacing(1),
    width: "fit-content",
  },
  header: {
    flex: 1,
    display:"inline-block",
    marginTop:-3,
    marginLeft:-10,
  },
  headerButtons: {
    float:"right",
    marginTop:-4,
    marginRight:-4,
  },
  headerTitle: {
    float:"left",
  },
  mobileBtn: {
    display: "inline-flex",
    justifyContent: "space-between",
    flex: 1,
    marginRight: theme.spacing(1)
  },
  vendorNameInput: {
    minWidth: "46%",
    [theme.breakpoints.down("sm")]: {
      minWidth: "95%",
    },
    [theme.breakpoints.down("md")]: {
      minWidth: "95%",
    },
  },
  contactLabels: {
      marginTop: theme.spacing(1),
      [theme.breakpoints.down("sm")]: {
        minWidth: "95%",
      },
      [theme.breakpoints.down("md")]: {
        minWidth: "95%",
      },
  },
  nameInputs: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "46.5%",
    [theme.breakpoints.up("lg")]: {
      minwidth: "95%",
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: "95%",
    },
    [theme.breakpoints.down("md")]: {
      minWidth: "95%",
    },
  },
  addressInputs: {
      marginTop: theme.spacing(1),
      // width: "435px",
      width: "95%",
      [theme.breakpoints.down("sm")]: {
        minWidth: "95%",
      },
      [theme.breakpoints.down("md")]: {
        minWidth: "95%",
      },
  },
  cityInput: {
    marginTop: theme.spacing(1),
    width: "95%",
    [theme.breakpoints.down("sm")]: {
      Width: "100%",
    },
    [theme.breakpoints.down("md")]: {
      Width: "95%",
    },
  },
  stateInput: {
    marginTop: theme.spacing(1),
    width: "25%",
    [theme.breakpoints.down("sm")]: {
      minWidth: "95%",
    },
    [theme.breakpoints.down("md")]: {
      minWidth: "95%",
    },
  },
  zipInput: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    width: "45%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(0),
      minWidth: "95%",
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: theme.spacing(0),
      minWidth: "95%",
    },
  },
  phoneInputs: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "95%",
    [theme.breakpoints.down("sm")]: {
      minWidth: "95%",
    },
    [theme.breakpoints.down("md")]: {
      minWidth: "95%",
    },
  },
  emailInput: {
    marginTop: theme.spacing(1),
    width: "95%",
    [theme.breakpoints.down("sm")]: {
      minWidth: "95%",
    },
    [theme.breakpoints.down("md")]: {
      minWidth: "95%",
    },
  },
  credentialButtons: {
    marginTop:theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  btnControl: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      marginRight: "5%",
      width: "95%"
    },
  },
  emailDrawerButton: {
    marginTop: theme.spacing(1),
    marginRight: "5%",
    [theme.breakpoints.down("md")]: {
      width: "95%"
    },
  },
  centerContentColumn: {
    textAlign: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  },
  centerContentRow: {
    display: "inline-block",
    verticalAlign:"middle",
    marginLeft:20,
    [theme.breakpoints.down("md")]: {
      
    },
  },
}));
