import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import StyledPanel from "../Panels/StyledPanel";
import { useStyles } from "./style";
import { Typography, Button, Grid, Drawer, InputAdornment, IconButton } from "@material-ui/core";
import MuiPhoneNumber from "material-ui-phone-number";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";
import { Formik, Form, Field } from "formik";
import CredentialService from "../../services/CredentialService";
import apiClient from "../../auth/apiClient";
import { useEnqueueSnackbar } from "../../hooks/useEnqueueSnackbar";
import clsx from "clsx";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import _ from "lodash";

const credentialService = new CredentialService(apiClient);
const ThirdPartyVendorConfigurationPanel = ({
  vendorDetails,
  apiCredentials,
  handleSave,
  disabledState,
  handleGeneration,
  handleRevoke,
  editPermission
}) => {
  const classes = useStyles();
  const enqueueSnackbar = useEnqueueSnackbar();

  const ThirdPartySchema = Yup.object().shape({
    vendorName: Yup.string().required("Required"),
    primaryFirstName: Yup.string().required("Required"),
    primaryLastName: Yup.string().required("Required"),
    primaryAddress1: Yup.string().required("Required"),
    primaryCity: Yup.string().required("Required"),
    primaryState: Yup.string()
      .length(2)
      .required("Required")
      .matches(/^[A-Za-z]+$/, "Only letters are allowed"),
    primaryZipCode: Yup.string()
      .matches(/^\d{5}(?:[-\s]\d{4})?$/, "A valid zip code is required")
      .required("Required"),
    primaryPhone1: Yup.string().required("Required"),
    devPhone1: Yup.string().required("Required"),
    primaryLastName: Yup.string().required("Required"),
    primaryLastName: Yup.string().required("Required"),
    primaryEmail: Yup.string()
      .email("A valid email address is required")
      .required("Required"),
    devEmail: Yup.string()
      .email("A valid email address is required")
      .required("Required"),
    devFirstName: Yup.string().required("Required"),
    devLastName: Yup.string().required("Required")
  });

  const [showClientId, setShowClientId] = useState(false);
  const handleClickShowClientId = () => setShowClientId((show) => !show);
  const [showClientSecret, setShowClientSecret] = useState(false);
  const handleClickShowClientSecret = () => setShowClientSecret((show) => !show);
  const handleMouseDownVisibilityIcon = (event) => event.preventDefault();

  const [credentialsExist, setCredentialsExist] = useState(true);

  const formatEmail = () => {
    var emailBody = "<div style='white-space:pre-wrap'>";
    emailBody += `Welcome to the Amano One Platform. Your API credentials are: <br />`;
    emailBody += `Client ID: ${apiCredentials.id} <br />`;
    emailBody += `Secret: ${apiCredentials.clientSecret} <br />`;
    emailBody += "Please contact swtools@amanomcgann.com with any questions.";
    emailBody += "</div>";
    return emailBody;
  };

  const sendEmail = async () => {
    var emailRequest = {
      CCReplyTo: false,
      ToAddress:
        vendorDetails.contacts[0].emails[0].emailAddress +
        ";" +
        vendorDetails.contacts[1].emails[0].emailAddress,
      ReplyToAddress: "",
      Subject: "Welcome to the Amano One Platform",
      Body: formatEmail(),
      Attachments: []
    };

    try {
      await credentialService.SendEmail(emailRequest);
      enqueueSnackbar("Successfully sent email", { variant: "success" });
    } catch {
      enqueueSnackbar("Failed to send email", {
        variant: "error",
        tag: "FailedToSendEmail"
      });
    }
  };

  const handleGenerateCredentials = () => {
    handleGeneration(vendorDetails.subscriberID)
      .then(() => {
        setShowClientId(true);
        setShowClientSecret(true);
      });
  }


  useEffect(() => {
    setCredentialsExist(!_.isEmpty(apiCredentials.id) && apiCredentials.id !== 'revoked');
  }, [apiCredentials])

  if (Object.keys(vendorDetails).length === 0) {
    return (
      <StyledPanel
        data-id="imAPanel"
        headerContent={
          <div className={classes.header}>
            <div className={classes.headerLeft}>
              <Typography>Third Party Vendor Details</Typography>
            </div>
          </div>
        }
        cardContent={
          <div className={classes.headerButtons}>
            <Typography data-id="FailueText">
              Failed to get vendor defaults!
            </Typography>
          </div>
        }
      />
    );
  }

  return (
    <Formik
      onSubmit={values => handleSave(values)}
      enableReinitialize
      validateOnChange={false}
      initialValues={{
        vendorName: vendorDetails?.description,
        primaryFirstName: vendorDetails?.contacts[0]?.firstName,
        primaryLastName: vendorDetails?.contacts[0]?.lastName,
        primaryAddress1: vendorDetails?.contacts[0]?.addresses[0]?.addressLine1,
        primaryAddress2: vendorDetails?.contacts[0]?.addresses[0]?.addressLine2,
        primaryCity: vendorDetails?.contacts[0]?.addresses[0]?.city,
        primaryState: vendorDetails?.contacts[0]?.addresses[0]?.state,
        primaryZipCode: vendorDetails?.contacts[0]?.addresses[0]?.postalCode,
        primaryEmail: vendorDetails?.contacts[0]?.emails[0]?.emailAddress,
        primaryPhone1: vendorDetails?.contacts[0]?.phoneNumbers[0]?.number,
        primaryPhone2: vendorDetails?.contacts[0]?.phoneNumbers[1]?.number,
        devPhone1: vendorDetails?.contacts[1]?.phoneNumbers[0]?.number,
        devPhone2: vendorDetails?.contacts[1]?.phoneNumbers[1]?.number,
        devFirstName: vendorDetails?.contacts[1]?.firstName,
        devLastName: vendorDetails?.contacts[1]?.lastName,
        devEmail: vendorDetails?.contacts[1]?.emails[0]?.emailAddress,
        ClientID: apiCredentials?.id,
        ClientSecret: apiCredentials?.clientSecret
      }}
      validationSchema={ThirdPartySchema}>
      {({ submitForm, setFieldValue }) => (
        <Form>
          <StyledPanel
            headerContent={
              <div className={classes.header}>
                <div className={clsx(["vendor", "title", classes.headerTitle])}>
                  <Typography>Third Party Integrator</Typography>
                </div>

                <div className={classes.headerButtons}>
                  {editPermission && (
                    <Button
                      className={clsx("vendor", "save", "button", classes.mobileBtn)}
                      variant="contained"
                      color="primary"
                      onClick={submitForm}>
                      Save
                    </Button>
                  )}
                </div>
              </div>
            }
            cardContent={
              <div className={classes.cardContent}>
                <Grid container>
                  <Grid item lg={12} md={12} sm={12}>
                    <Field
                      className={clsx(["vendor-name", classes.vendorNameInput])}
                      label="Vendor Name"
                      component={TextField}
                      variant="outlined"
                      name="vendorName"
                      disabled={false}
                    />
                  </Grid>
                  <Grid item lg={6} md={12} sm={12}>
                    <Typography variant="h5" className={classes.contactLabels}>
                      Primary Contact
                    </Typography>
                    <Field
                      component={TextField}
                      className={clsx("vendor", "primary", "first-name", classes.nameInputs)}
                      variant="outlined"
                      label="First Name"
                      name="primaryFirstName"
                      disabled={false}
                    />

                    <Field
                      component={TextField}
                      className={clsx("vendor", "primary", "last-name", classes.nameInputs)}
                      variant="outlined"
                      name="primaryLastName"
                      label="Last Name"
                      disabled={false}
                    />

                    <Field
                      component={TextField}
                      className={clsx("vendor", "primary", "address-1", classes.addressInputs)}
                      label="Address 1"
                      name="primaryAddress1"
                      variant="outlined"
                      disabled={false}
                    />

                    <Field
                      component={TextField}
                      className={clsx("vendor", "primary", "address-2", classes.addressInputs)}
                      label="Address 2"
                      name="primaryAddress2"
                      variant="outlined"
                      disabled={false}
                    />
                    <Field
                      component={TextField}
                      className={clsx("vendor", "primary", "city", classes.cityInput)}
                      label="City"
                      name="primaryCity"
                      variant="outlined"
                      disabled={false}
                    />

                    <Field
                      component={TextField}
                      className={clsx("vendor", "primary", "state", classes.stateInput)}
                      label="State"
                      name="primaryState"
                      variant="outlined"
                      disabled={false}
                    />

                    <Field
                      component={TextField}
                      className={clsx("vendor", "primary", "zipcode", classes.zipInput)}
                      label="Zip Code"
                      name="primaryZipCode"
                      variant="outlined"
                      disabled={false}
                    />

                    <Field
                      validateOnBlur
                      validateOnChange
                      name="primaryPhone1"
                      render={({ field, form }) => (
                        <MuiPhoneNumber
                          className={clsx("vendor", "primary", "phone-1", classes.phoneInputs)}
                          defaultCountry={"us"}
                          regions={["europe", "north-america"]}
                          disableAreaCodes
                          variant="outlined"
                          disabled={false}
                          value={field.value}
                          label="Phone 1"
                          name="primaryPhone1"
                          placeholder="Phone 1"
                          disableCountryCode
                          onChange={e => setFieldValue("primaryPhone1", e)}
                          error={Boolean(
                            form.errors.primaryPhone1 &&
                            form.touched.primaryPhone1
                          )}
                          helperText={form.errors.primaryPhone1}
                        />
                      )}
                    />

                    <Field
                      validateOnBlur
                      validateOnChange
                      name="primaryPhone2"
                      render={({ field, form }) => (
                        <MuiPhoneNumber
                          className={clsx("vendor", "primary", "phone-2", classes.phoneInputs)}
                          defaultCountry={"us"}
                          regions={["europe", "north-america"]}
                          disableAreaCodes
                          disabled={false}
                          disableCountryCode
                          placeholder="Phone 2"
                          variant="outlined"
                          label="Phone 2"
                          name="primaryPhone2"
                          value={field.value}
                          onChange={e => setFieldValue("primaryPhone2", e)}
                        />
                      )}
                    />

                    <Field
                      component={TextField}
                      className={clsx("vendor", "primary", "email", classes.emailInput)}
                      label="Email Address"
                      variant="outlined"
                      name="primaryEmail"
                      disabled={false}
                    />
                  </Grid>

                  <Grid item lg={6} md={12} sm={12}>
                    <Typography variant="h5" className={classes.contactLabels}>
                      Developer Contact
                    </Typography>
                    <Field
                      component={TextField}
                      className={clsx("vendor", "primary", "first-name", classes.nameInputs)}
                      label="First Name"
                      name="devFirstName"
                      variant="outlined"
                      disabled={false}
                    />

                    <Field
                      component={TextField}
                      className={clsx("vendor", "primary", "last-name", classes.nameInputs)}
                      label="Last Name"
                      name="devLastName"
                      variant="outlined"
                      disabled={false}
                    />

                    <Field
                      component={TextField}
                      className={clsx("vendor", "primary", "dev-email", classes.emailInput)}
                      label="Email Address"
                      variant="outlined"
                      name="devEmail"
                      disabled={false}
                    />

                    <Field
                      validateOnBlur
                      validateOnChange
                      name="devPhone1"
                      render={({ field, form }) => (
                        <MuiPhoneNumber
                          className={clsx("vendor", "primary", "dev-phone-1", classes.phoneInputs)}
                          defaultCountry={"us"}
                          regions={["europe", "north-america"]}
                          disableAreaCodes
                          variant="outlined"
                          label="Phone 1"
                          placeholder="Phone 1"
                          disableCountryCode
                          disabled={false}
                          value={field.value}
                          onChange={e => setFieldValue("devPhone1", e)}
                          error={Boolean(
                            form.errors.devPhone1 && form.touched.devPhone1
                          )}
                          helperText={form.errors.devPhone1}
                        />
                      )}
                    />

                    <Field
                      validateOnBlur
                      validateOnChange
                      name="devPhone2"
                      render={({ field }) => (
                        <MuiPhoneNumber
                          defaultCountry={"us"}
                          regions={["europe", "north-america"]}
                          disableAreaCodes
                          disableCountryCode
                          variant="outlined"
                          label="Phone 2"
                          placeholder="Phone 2"
                          value={field.value}
                          onChange={e => setFieldValue("devPhone2", e)}
                          className={clsx("vendor", "primary", "dev-phone-2", classes.phoneInputs)}
                          disabled={false}
                        />
                      )}
                    />

                    <div style={{ marginTop: "10%" }}>
                      <Typography
                        variant="h5"
                        className={classes.contactLabels}>
                        API Credentials
                      </Typography>
                      <Grid item lg={12} md={12} sm={12}>
                        <Field key='clientIDField'
                          component={TextField}
                          className={clsx("vendor", "client-id", classes.emailInput)}
                          label="Client ID"
                          variant="outlined"
                          name="ClientID"
                          data-id="clientID"
                          disabled={false}
                          InputProps={{
                            type: (showClientId || !credentialsExist) ? "text" : "password",
                            readOnly: true,
                            endAdornment: credentialsExist && (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowClientId}
                                  onMouseDown={handleMouseDownVisibilityIcon}
                                  edge="end">
                                  {showClientId ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />

                        <Field key='clientSecretField'
                          component={TextField}
                          className={clsx("vendor", "client-secret", classes.emailInput)}
                          label="Client Secret"
                          variant="outlined"
                          name="ClientSecret"
                          data-id="clientSecret"
                          disabled={false}
                          InputProps={{
                            type: (showClientSecret || !credentialsExist) ? "text" : "password",
                            readOnly: true,
                            endAdornment: credentialsExist && (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowClientSecret}
                                  onMouseDown={handleMouseDownVisibilityIcon}
                                  edge="end">
                                  {showClientSecret ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                        
                        {disabledState === false && editPermission && (
                          <>
                            <Button
                              className={clsx("revoke-api", "button", classes.emailDrawerButton)}
                              variant="contained"
                              color="secondary"
                              style={{ float: "right" }}
                              onClick={() => handleRevoke(vendorDetails.subscriberID)}>
                              Revoke API Access
                            </Button>
                            <Button
                              className={clsx("generate-api", "button", classes.btnControl)}
                              variant="contained"
                              color="primary"
                              style={{ float: "right" }}
                              onClick={handleGenerateCredentials}>
                              Generate new credentials
                            </Button>
                            {credentialsExist && (
                              <Button
                                className={clsx("send-email", "button", classes.btnControl)}
                                variant="contained"
                                color="primary"
                                style={{ float: "right" }}
                                onClick={sendEmail}>
                                Email
                              </Button>
                            )}
                          </>
                        )}
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </div>
            }
          />
        </Form>
      )}
    </Formik>
  );
};

ThirdPartyVendorConfigurationPanel.defaultProps = {
  vendorDetails: {}
};

ThirdPartyVendorConfigurationPanel.propTypes = {
  className: PropTypes.string
};

export default ThirdPartyVendorConfigurationPanel;
