import { blueGrey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  navLinkActive: {
    backgroundColor: theme.palette.action.selected,
    border: '1px solid red !important',
  },
  navLink: {
    color: blueGrey[50],
    //color: theme.palette.text?.primary,
    '&.active' : {
      backgroundColor: theme.palette.primary.light,
    }
  },
  subCategory: {
    marginLeft: theme.spacing(2),
  },
  caretIcon: {
    "& svg": {
      color: theme.palette.grey[500],
    },
  },
}));
