import React, { useState, useEffect, useRef } from "react";
import { useStyles } from "./styles";
import PropTypes from "prop-types";
import useHasPermissions from "../../../../hooks/useHasPermissions";
import { useEnqueueSnackbar } from "../../../../hooks/useEnqueueSnackbar";
import moment from "moment-timezone";
import { Box, Button, Tooltip, Typography, Grid } from "@material-ui/core";
import clsx from "clsx";
import CredentialService from "../../../../services/CredentialService";
import apiClient from "../../../../auth/apiClient";
import MultiValidationOfferDropdown from "../../../Dropdowns/MultiValidationOfferDropdown";
import * as _ from "lodash";
import ColoredLine from "../../../ColoredLine";
import { useSelector } from "react-redux";
import useCurrentFacility from "../../../../hooks/useCurrentFacility";
import ValidationMemo from "../../../ValidationMemo"
import { TICKET_SEARCH_TYPE } from "../../../../constants";

const credentialService = new CredentialService(apiClient);

const formDescriptionText = (foundValOffer, appliedVal) => {
  const activityDate = moment
    .utc(appliedVal?.activationDate)
    .local()
    .format("LLLL");
  const formattedAmount = foundValOffer?.amount?.toFixed(2);
  switch (foundValOffer.type) {
    case "Amount":
      return `$${-1 * formattedAmount} off applied on ${activityDate}`;
    case "Flat Fee":
      return `Flat Fee of $${formattedAmount} applied on ${activityDate}`;
    case "Percentage":
      return `${-1 * formattedAmount}% off applied on ${activityDate}`;
    case "Rate Switch":
      return `Rate was changed to ${foundValOffer.name} on ${activityDate}`;
    case "Adjust Entry Time":
      return `Entry Time moved forward ${-1 * formattedAmount} minutes`;
    case "Adjust Exit Time":
      return `Exit Time moved backward ${-1 * formattedAmount} minutes`;
  }
};

const InternalFormat = "YYYY-MM-DDTHH:mm:ssZ";

/**
 * Iterates through the validations applied to the ticket,
 * finds the corresponding validation offer that we already have,
 * formats the validation applied entry with quick summary
 * @param ticketValidations
 */
const formatValidationsApplied = (ticketValidations, offers, classes) => {
  let formattedValidations = [];
  ticketValidations.map((appliedVal, index) => {
    const foundValOffer = _.find(offers.map((group) => group.offers).flat(), {
      id: appliedVal.validationOfferID,
    });
    if (foundValOffer) {
      formattedValidations.push({
        id: appliedVal.validationsInventoryID,
        content: (
          <>
            <div key={`applied-validation-${index}`}>
              {formDescriptionText(foundValOffer, appliedVal)}
            </div>
            <div key={`applied-validation-memo-${index}`} className={classes.memoText}>
              {appliedVal.memo}
            </div>
          </>
        ),
      });
    }
  });
  return formattedValidations;
};

const TicketValidationRow = ({ ticket, validationOffers, searchType, autoApplyValidationOffer, setSearchTerm, automaticallyApplyValidationEnabled }) => {
  const classes = useStyles();
  const { hasPermissions } = useHasPermissions();
  const validateTicketsPermission = hasPermissions(["validatetickets"]);
  const [selectedValOffer, setSelectedValOffer] = useState(autoApplyValidationOffer);
  const enqueueSnackbar = useEnqueueSnackbar();
  const currentUserID = useSelector((state) => state.user?.UserID);
  const [validations, setValidations] = useState(ticket?.validations ?? []);
  const { facilityID } = useCurrentFacility();
  const [memoData, setMemoData] = useState("");
  const validationMemoRef = useRef(null);

  const removeValidation = async (validationsInventoryID) => {
    try {
      var response = await credentialService.unapplyOnlineValidation(
        validationsInventoryID,
        ticket.parkingTransactionID
      );
      if (response.status != 200) {
        enqueueSnackbar("Failed to remove validation from ticket", {
          variant: "error",
          tag: "FailedToRemoveValidationFromTicket",
        });
      } else {
        setValidations(
          validations.filter(
            (validation) =>
              validation.validationsInventoryID !== validationsInventoryID
          )
        );
        enqueueSnackbar("Successfully removed validation from ticket", {
          variant: "success",
        });
      }
    } catch (err) {
      console.error("Unclaim validation error ", err);
      enqueueSnackbar("Failed to remove validation from ticket", {
        variant: "error",
        tag: "FailedToRemoveValidationFromTicket",
      });
    }
  };

  useEffect(() => {
    setSelectedValOffer("");
    setValidations(ticket?.validations ?? []);
  }, [ticket]);

  const sendApplyValidation = async () => {
    if (!ticket) return;
    if (!selectedValOffer) return;

    const activationDate = moment(Date.now()).format(InternalFormat);

    const validationReq = {
      type: selectedValOffer.type,
      kind: "online",
      format: "QR Code",
      barcodeValue: ticket.ticketId,
      status: "Used",
      contractID: ticket.contractId ?? null,
      validationBundleID: selectedValOffer.validationBundleID,
      currentUses: 1,
      maxUses: 1,
      maxUsesPerTransaction: 1,
      isValid: true,
      amount: selectedValOffer.amount,
      entityID: ticket.entityID ?? null,
      rateBlobID: selectedValOffer.rateBlobID ?? null,
      creationDate: moment(Date.now()).format(InternalFormat),
      activationDate,
      validationOfferID: selectedValOffer.id,
      isPersistant: true,
      userID: currentUserID,
    };
    let validationResponse;
    try {
      validationResponse = await credentialService.CreateValidation(
        validationReq
      );
      if (validationResponse.status != 200) {
        enqueueSnackbar("Failed to apply validation", {
          variant: "error",
          tag: "FailedToApplyValidation",
        });
        return;
      }
    } catch (err) {
      enqueueSnackbar("Failed to apply validation", {
        variant: "error",
        tag: "FailedToApplyValidation",
      });
      return;
    }

    try {
      const claimedResponse = await credentialService.tryApplyValidationWithinLimit(
        validationResponse.data.id,
        ticket.parkingTransactionID,
        facilityID,
        memoData,
      );
      if (claimedResponse.status != 200) {
        enqueueSnackbar("Failed to apply validation", {
          variant: "error",
          tag: "FailedToApplyValidation",
        });
      } else {
        setValidations((prev) => [
          {
            validationOfferID: selectedValOffer.id,
            validationsInventoryID: validationResponse.data.id,
            activationDate,
            amount: selectedValOffer.amount,
            type: selectedValOffer.type,
            name: selectedValOffer.name,
            memo: memoData,
          },
          ...prev,
        ]);

        if (validationMemoRef.current && validationMemoRef.current.resetValidationMemo) {
          validationMemoRef.current.resetValidationMemo();
        }

        enqueueSnackbar("Successfully applied validation", {
          variant: "success",
        });
      }
    } catch (err) {
      if(err.response?.data === "Limit Exceed"){
        enqueueSnackbar("The allowed validation limit has been reached for the ticket", {
          variant: "error",
          tag: "FailedToApplyValidation",
        });
      }else{
        enqueueSnackbar("Failed to apply validation", {
          variant: "error",
          tag: "FailedToApplyValidation",
        });
      }
    }
    if (automaticallyApplyValidationEnabled) {
      setSearchTerm(null);
    }
  };

  const shouldBeDisabled = () => {
    return selectedValOffer === "";
  };

  const handleValSelect = (offer) => {
    setSelectedValOffer(offer);
  };

  const handleMemoChange = (data) => {
    setMemoData(data);
  };

  function hasValidationOffers() {
    return validationOffers &&  validationOffers.some(x => x.offers && x.offers.length > 0);
  }

  return (
    <div
      key={ticket?.ticketID}
      className={clsx(
        `ticket-validation-row-${ticket?.ticketID}`,
        classes.contentRoot
      )}
    >
      <div className={clsx(`ticket-info-${ticket?.ticketID}`, classes.ticketInfo)}>
        <Grid container alignItems="center" spacing={1} rowSpacing={1}
          className={clsx("lpr-table", classes.validationGrid)}>
          <Grid item xs={6}>
            <Tooltip title={"Ticket ID"} aria-label="ticket-id">
              <Typography className={classes.ticketIdText} color="primary" variant="h5">
                {ticket?.ticketID?.slice(ticket.ticketID?.length - 12)}
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={6} >
            {searchType === TICKET_SEARCH_TYPE.LICENSE_PLATE && (
              <>
                <Tooltip title={"License Plate"} aria-label="LicensePlate-id">
                  <Typography className={classes.ticketIdText} color="primary" variant="h5" >
                    {ticket.licensePlate}
                  </Typography>
                </Tooltip>
              </>
            )}
          </Grid>
          <Grid item xs={6}>
            <Tooltip title="Last Activity" aria-label="last-activity">
              <Box width="fit-content">
                {moment
                  .utc(ticket?.issuedDate ?? ticket?.activityDate)
                  .local()
                  .format("LLLL")}
              </Box>
            </Tooltip>
          </Grid>
        </Grid>
      </div>
      <div
        className={clsx(
          `validate-section-${ticket?.ticketID}`,
          classes.validationDescriptionContainer
        )}
      >
        {validateTicketsPermission && (
          <>
            <Grid
              container
              alignItems="center"
              spacing={1}
              rowSpacing={1}
              className={clsx("ticket-table", classes.validationGrid)}>
              <Grid item xs={7} sm={8} md={8} lg={9} xl={9}>
                <MultiValidationOfferDropdown
                  className={clsx(
                    `select-val-offer-${ticket?.ticketID}`,
                    classes.multiValDropdown
                  )}
                  validationOffers={validationOffers}
                  onChange={handleValSelect}
                  autoApplyValidationOffer={autoApplyValidationOffer}
                />
              </Grid>
              {hasValidationOffers() && (
                <>
                  <Grid item xs={1} >
                    <Button
                      className={clsx([
                        `btn-applyval-${ticket?.ticketID}`,
                        classes.applyButton,
                      ])}
                      color="primary"
                      onClick={sendApplyValidation}
                      variant="contained"
                      disabled={shouldBeDisabled()}
                      data-testid={`btn-applyval-${ticket?.ticketID}`}
                    >
                      Apply
                    </Button>
                  </Grid>
                  <Grid item xs={7} sm={8} md={8} lg={9} xl={9}>
                    <ValidationMemo
                      ref={validationMemoRef}
                      onInputChange={handleMemoChange}
                      maxLength={120}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={9} sm={10} md={10} lg={11} xl={11}>
                <div className={classes.validationsApplied}>
                  {validations?.length > 0 &&
                    formatValidationsApplied(validations, validationOffers, classes).map(
                      (val, index) => (
                        <>
                          <div
                            key={index}
                            className={classes.validationDescriptionContainer}
                          >
                            <div
                              className={clsx(
                                `applied-validation-${ticket?.ticketID}-${val.id}`,
                                classes.appliedValidation
                              )}
                            >
                              {val.content}
                            </div>
                            <Button
                              className={clsx([
                                `btn-remove-${ticket?.ticketID}-${val.id}`,
                                classes.removeButton,
                              ])}
                              variant="contained"
                              color="secondary"
                              onClick={() => {
                                removeValidation(val.id);
                              }}
                            >
                              Remove
                            </Button>
                          </div>
                          <ColoredLine />
                        </>
                      )
                    )}
                </div>
              </Grid>
            </Grid>
          </>
        )}
      </div>
    </div>
  );
};

export default TicketValidationRow;

TicketValidationRow.defaultProps = {
  validationOffers: [],
  searchType: 0
};

TicketValidationRow.propTypes = {
  validationOffers: PropTypes.arrayOf(
    PropTypes.shape({
      account: PropTypes.string.isRequired,
      offers: PropTypes.arrayOf(PropTypes.object).isRequired,
    })
  ),
  searchType: PropTypes.number,
  autoApplyValidationOffer: PropTypes.object,
  setSearchTerm: PropTypes.func,
  automaticallyApplyValidationEnabled: PropTypes.bool
};
