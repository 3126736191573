import React, { useState, useEffect } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import PageableEntity, { PaginatorLocations } from "../../../PageableEntity";
import { useEnqueueSnackbar } from "../../../../hooks/useEnqueueSnackbar";
import useTaxRatesContext from "../../../../hooks/useTaxRatesContext";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import CreateEditTaxRates from "../CreateEditTaxRates";
import { Grid, Box } from "@material-ui/core";
import { onTaxRateFilterChange } from "../../../../reducers/taxes/taxRateReducer";
import useHasPermissions from "../../../../hooks/useHasPermissions";
import useCurrentFacility from "../../../../hooks/useCurrentFacility";

function getRowsByHeight(height) {
  return Math.round(height / 200);
}

const TaxRatesManagementContainer = () => {
  const enqueueSnackbar = useEnqueueSnackbar();
  const { facilityID } = useCurrentFacility();
  const history = useHistory();
  const [taxRates, setTaxRates] = useState({
    totalCount: 0,
    collection: [],
    display: []
  });
  const {
    getPageableTaxRates,
    deleteTaxRates,
    taxRateReducer
  } = useTaxRatesContext();
  const { height } = useWindowDimensions();
  const [itemLimit, setItemLimit] = useState(getRowsByHeight(height));
  const [taxRateData, taxRateDispatch] = taxRateReducer;
  const { hasPermissions } = useHasPermissions();
  const taxesWrite = hasPermissions(["taxes.write"]);

  useEffect(() => {
    if (height) {
      setItemLimit(height > 0 ? getRowsByHeight(height) : 0);
    }
  }, [height]);

  useEffect(() => {
    updateTaxRatesSet(
      taxRateData.taxRatePage,
      itemLimit,
      taxRateData.taxRateSearchTerm
    );
  }, [
    facilityID,
    itemLimit,
    taxRateData.taxRatePage,
    taxRateData.taxRateSearchTerm
  ]);

  const updateTaxRatesSet = (page, limit = itemLimit, searchTerm = "") => {
    getPageableTaxRates(
      facilityID,
      limit,
      page ? (page - 1) * limit : 0,
      searchTerm
    )
      .then(res => {
        if (res.status === 200) {
          setTaxRates(res.data);
          formatTaxRatesData(res.data.collection);
        } else {
          enqueueSnackbar("We encountered a problem retrieving tax rates", {
            variant: "error",
            tag: "FailedToRetrieveTaxRate"
          });
        }
      })
      .catch(() => {
        enqueueSnackbar("Failed to retrieve tax rates", {
          variant: "error",
          tag: "FailedToRetrieveTaxRate"
        });
      });
  };

  const formatTaxRatesData = rawTaxRates => {
    const formatted = rawTaxRates.map(taxRate => {
      return {
        id: taxRate.taxRateID,
        displayContent: (
          <Grid container spacing={2}>
            <Grid item container direction="column">
              <Grid item>
                <Box fontWeight="bold" fontSize="large">
                  {taxRate.description + ` (${taxRate.taxAmount}%)`}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        )
      };
    });

    setTaxRates(prev => ({ ...prev, display: formatted }));
  };

  const handleAddTaxRate = () => {
    history.push(`/taxmgmt/tax-rates/-1`);
  };

  const handleTaxRateSelected = taxRateID => {
    history.push(`/taxmgmt/tax-rates/${taxRateID}`);
  };

  const handleTaxRateSearch = searchTerm => {
    taxRateDispatch({
      type: onTaxRateFilterChange,
      payload: { taxRateSearchTerm: searchTerm, taxRatePage: 1 }
    });
  };

  const handleDeleteTaxRates = taxRateIDs => {
    if (taxRateIDs?.length === 0) {
      return;
    }

    deleteTaxRates(facilityID, taxRateIDs)
      .then(res => {
        if (res.status === 200) {
          var taxRateIDsNotDeleted = res.data;
          if (taxRateIDsNotDeleted.length > 0) {
            const message = `Some tax rates could not be deleted. ${taxRateIDs.length -
              taxRateIDsNotDeleted.length} of ${
              taxRateIDs.length
            } tax rates where deleted`;
            enqueueSnackbar(message, {
              variant: "warning"
            });
          } else {
            const message = `Successfully deleted ${taxRateIDs.length -
              taxRateIDsNotDeleted.length} tax rate${
              taxRateIDs.length > 1 ? `s` : ``
            }`;
            enqueueSnackbar(message, {
              variant: "success"
            });
          }
          refreshList();
        } else {
          enqueueSnackbar("We encountered a problem deleting tax rates", {
            variant: "error",
            tag: "FailedToDeleteTaxRates"
          });
          refreshList();
        }
      })
      .catch(error => {
        enqueueSnackbar("Error deleting tax rates", {
          variant: "error",
          tag: "ErrorDeletingTaxRates"
        });
      });
  };

  const handlePageChange = (e, page) => {
    taxRateDispatch({
      type: onTaxRateFilterChange,
      payload: { taxRatePage: page }
    });
  };

  const refreshList = () => {
    updateTaxRatesSet(
      taxRateData.taxRatePage,
      itemLimit,
      taxRateData.taxRateSearchTerm
    );
  };

  return (
    <Switch>
      <Route exact path="/taxmgmt/tax-rates">
        <PageableEntity
          dataID="taxrates-pageable"
          title="Tax Rates Management"
          searchBarLabel="Tax Rates"
          addBtnLabel="Add Tax Rate"
          addBtnClicked={handleAddTaxRate}
          items={taxRates.display}
          onItemClicked={handleTaxRateSelected}
          onSearchChange={handleTaxRateSearch}
          totalCount={Math.ceil(taxRates.totalCount / itemLimit)}
          onPageChange={handlePageChange}
          searchDebounce={100}
          deleteBtnClicked={handleDeleteTaxRates}
          hideDeleteBtn={!taxesWrite}
          hideAddBtn={!taxesWrite}
          currentPage={taxRateData.taxRatePage ?? 1}
          defaultSearch={taxRateData.taxRateSearchTerm}
          paginatorLocation={PaginatorLocations.TOP}
        />
      </Route>
      <Route exact path="/taxmgmt/tax-rates/:taxRateID">
        <CreateEditTaxRates onUpdate={refreshList} taxesWrite={taxesWrite} />
      </Route>
    </Switch>
  );
};

export default TaxRatesManagementContainer;
