import React, { useEffect, useState, useRef } from "react";
import { useStyles } from "./styles";
import PropTypes from "prop-types";
import {
  Button,
  Container,
  Divider,
  LinearProgress,
  Paper,
  Typography,
} from "@material-ui/core";
import { DataGrid, GridOverlay } from "@material-ui/data-grid";
import clsx from "clsx";

import useHubContext from "../../hooks/useHubContext";
import * as c from "../../constants";

import { useSelector, useDispatch } from "react-redux";
import {
  changeContext,
  loadEntities,
  modifyPropertyOnEntity,
  GetProperty,
} from "../../state/slices/entities";
import ReactJson from "react-json-view";

export const JasonTestComponent = ({ entityID }) => {
  const dispatch = useDispatch();
  const entities = useSelector((state) => state.entities);

  const [showJSONTree, setShowJSONTree] = React.useState(false);

  const { portalHub, Connected: PortalHubConnected } = useHubContext();

  //useEffect for only watching Loaded
  useEffect(() => {
    console.log("loaded changed to ", entities.Loaded);
    return () => {};
  }, [entities.Loaded]);

  //useEffect-loading
  useEffect(() => {
    console.log("loading changed to ", entities.Loading);
    return () => {};
  }, [entities.Loading]);

  useEffect(() => {
    //portalhub watcher
    //sub
    portalHub.subscribe(
      "5d6c2ba6-b699-4c1b-88e0-ad9087452309_DEVICE_STATE_CHANGE",
      async (message) => {
        console.log(
          "INDIVIIDUAL TUNE IN : DERP YOU CLICKED THAT BUTTON I HEARD IT RESPOND"
        );
      }
    );
    return () => {
      //unsub
    };
  }, [portalHub.isOpen && portalHub.connection.state == "Connected"]);

  return (
    <div>
      <Typography variant="subtitle1">STATE STORE TESTS</Typography>
      <Divider />
      <div>
        Loading: {entities.Loading.toString()}
        <br />
        Loaded: {entities.Loaded.toString()}
        <br />
        ContextID: {entities.ContextID}
        <br />
        PropertyWatch:{" "}
        {GetProperty(
          entities.EntityList,
          "5d6c2ba6-b699-4c1b-88e0-ad9087452309",
          "type"
        )}
      </div>
      <Divider />
      <Button
        variant="outlined"
        onClick={() => {
          portalHub.invoke(c.PORTAL_TRIGGER, {
            entityID: "5d6c2ba6-b699-4c1b-88e0-ad9087452309",
            topic: c.DEVICE_STATE_READ_TOPIC, // send event to retrieve current device state
            method: "retrieve",
            action: "retrieve",
            message: `EntityID: 5d6c2ba6-b699-4c1b-88e0-ad9087452309`,
          });
        }}
      >
        SEND DEVICE_STATE REQUEST
      </Button>
      <Divider />
      <Button
        variant="outlined"
        onClick={() => dispatch(loadEntities(entityID))}
      >
        LOAD TREE (FOCUS CURRENT)
      </Button>
      <Button
        variant="outlined"
        disabled={!entities.Loaded}
        onClick={() =>
          dispatch(changeContext("4aa9fbcb-e0df-4ea3-9976-de8590a809fb"))
        }
      >
        FOCUS:AMI
      </Button>
      <Button
        variant="outlined"
        disabled={!entities.Loaded}
        onClick={() =>
          dispatch(changeContext("e3e2b062-258a-4705-971b-583cc3096cfe"))
        }
      >
        FOCUS :BOB
      </Button>
      <Button
        variant="outlined"
        disabled={!entities.Loaded}
        onClick={() =>
          dispatch(changeContext("5d6c2ba6-b699-4c1b-88e0-ad9087452309"))
        }
      >
        FOCUS: CT
      </Button>
      <Button
        variant="outlined"
        disabled={!entities.Loaded || entities.Context?.parententityid === null}
        onClick={() =>
          dispatch(changeContext(entities.Context?.parententityid))
        }
      >
        ^UP
      </Button>
      <Button
        variant="outlined"
        color="primary"
        onClick={() =>
          dispatch(
            modifyPropertyOnEntity({
              entityid: entities.ContextID,
              property: "state",
              value: "FART",
            })
          )
        }
      >
        SET PROP TEST
      </Button>
      <Divider />
      {entities.Context?.children != null &&
        entities.Context?.children.map((entity, index) => (
          <Button
            color="secondary"
            key={index}
            onClick={() => dispatch(changeContext(entity.entityid))}
          >
            FOCUS : {entity.name}
          </Button>
        ))}
      <Divider style={{ marginBottom: 10 }} />
      <Button
        onClick={() => {
          setShowJSONTree(!showJSONTree);
        }}
      >
        View {showJSONTree ? "Plain" : "Tree"}
      </Button>
      {!showJSONTree && <Paper>{JSON.stringify(entities.Context)}</Paper>}
      {showJSONTree && (
        <ReactJson
          enableClipboard={false}
          displayDataTypes={false}
          src={entities.Context}
          theme="monokai"
        />
      )}
      <Divider />
    </div>
  );
};

JasonTestComponent.defaultProps = { enttiyID: undefined };
JasonTestComponent.propTypes = { enttiyID: PropTypes.string };

export default JasonTestComponent;
