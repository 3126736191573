import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import { useForm, Controller } from "react-hook-form";
import { useStyles } from "./styles";
import FormControl from "@material-ui/core/FormControl";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from "clsx";

export const signInSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .required("Required"),
  password: Yup.string()
    .required("Required")
});

const SigninForm = props => {
  const classes = useStyles();
  const { handleSubmit, control, formState: { errors, isSubmitting } } = useForm({
    resolver: yupResolver(signInSchema)
  });

  return (
    <form
      className={classes.form}
      onSubmit={handleSubmit(async (values) => await props.onSubmit(values))}
      noValidate
    >
      <Controller
        name="email"
        control={control}
        render={({ field }) => <TextField
          {...field}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          type="email"
          id="email"
          label="Email Address"
          autoComplete="off"
          autoFocus
          error={!!errors.email}
          helperText={errors.email && errors.email.message}
        />}
      />

      <Controller
        name="password"
        control={control}
        render={({ field }) => <TextField
          {...field}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Password"
          type="password"
          id="password"
          autoComplete="off"
          error={!!errors.password}
          helperText={errors.password && errors.password.message}
        />}
      />


      <div className={classes.submitWrapper}>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={isSubmitting}
          className={clsx(`signin-button`)}
        >
          Sign In
        </Button>
        {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
      <Grid container>
        <Grid item xs>
          <Link component={RouterLink} to="/login/reset" variant="body2">
            Forgot password?
          </Link>
        </Grid>
      </Grid>
    </form>
  );
};

export default SigninForm;
