import React, { useState, useEffect } from "react";
import { Select, MenuItem, FormControl, InputLabel, Grid, Button } from "@material-ui/core";
import { useStyles } from "./styles";
import clsx from "clsx";
import PropTypes from 'prop-types';

const TicketFieldPicker = ({
  handleAddButton,
  existingTicketFields
}) => {
  const classes = useStyles();

  const [selectedTicketField, setSelectedTicketField] = useState({});
  const [ticketFieldList, setTicketFieldList] = useState([]);
  const [isAdding, setIsAdding] = useState(false);

  useEffect(() => {
    setTicketFieldList([...existingTicketFields]);
  }, [existingTicketFields]);

  const updateSelectedTicketField = (ticketField) => {
    const ticketFieldChosen = ticketFieldList.find(x => x.fieldName === ticketField) || {};
    setSelectedTicketField(ticketFieldChosen);
  }

  const handleAddButtonClick = async () => {
    setIsAdding(true);

    try {
      await handleAddButton(selectedTicketField);
    } finally {
      setIsAdding(false);
    }
  }

  return (
    <Grid container className={clsx("card-ticket-field-container", classes.dropDownAddRow)} direction="row">
      <Grid item>
        <FormControl className={clsx("form-control", classes.formControl)} variant="outlined">
          <InputLabel id="-ticket-field-label" className={clsx("dropdown-label")}>Select a ticket field</InputLabel>
          <Select
            value={selectedTicketField?.description}
            variant="outlined"
            labelId="-ticket-field-label"
            label="Select a ticket field"
            onChange={e => updateSelectedTicketField(e.target.value)}
            className={clsx("dropdown", classes.dropdown)}
          >
            {ticketFieldList.map((row) => (
              <MenuItem key={row.fieldID} value={row.fieldName} className={clsx(`${row.fieldName.replace(/\s+/g, "-")}`, 'menu-item', classes.selectMenuItem)}>
                {row.fieldName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={2} md={2} lg={2} className={classes.headerAddButton}>
        <Button
          className={clsx(`add-button`)}
          data-testid="add-button"
          color="primary"
          variant="contained"
          onClick={handleAddButtonClick}
          disabled={isAdding}
        >
          Add
        </Button>
      </Grid>
    </Grid>
  );
};

TicketFieldPicker.propTypes = {
  handleAddButton: PropTypes.func.isRequired,
  existingTicketFields: PropTypes.array.isRequired
};

TicketFieldPicker.defaultProps = {
  existingTicketFields: []
};

export default TicketFieldPicker;
