import React, { useState, useCallback } from "react";
import { NavLink } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import { useStyles } from "./styles";
import IconExpandLess from "@material-ui/icons/ExpandLess";
import IconExpandMore from "@material-ui/icons/ExpandMore";
import IconButton from "@material-ui/core/IconButton";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import useHasPermissions from "../../../hooks/useHasPermissions";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useFeatureFlags } from "../../../hooks/useFeatureFlags";
import _ from "lodash";

const NavListItems = (props) => {
  const { linksArray } = props;
  const classes = useStyles();
  const [childrenState, setChildrenState] = useState({});
  const { hasPermissions } = useHasPermissions();
  const isAdmin = useSelector((state) => state?.user?.isAdmin);
  const entityType = useSelector((state) => state.entities.Context.typeid);
  const ldFeatureFlags = useFlags();
  const featureFlags = useFeatureFlags();

  function handleClick(parent) {
    let copy = childrenState;
    const found = copy[parent];
    if (!found) {
      copy[parent] = true;
    } else {
      copy[parent] = !found;
    }
    setChildrenState((prev) => ({ ...prev, copy }));
  }

  const permissionFilter = useCallback(
    (navItem) => {
      if (navItem.amiAdminOnly === true) {
        return isAdmin;
      }

      if (navItem.showIfContainsAnyPermission) {
        const any = navItem.showIfContainsAnyPermission;
        return hasPermissions(any, true);
      } else {
        const permissions = navItem.permissions;
        return !permissions || hasPermissions(permissions);
      }
    },
    [isAdmin, hasPermissions]
  );

  const featureFlagFilter = useCallback(
    (navItem) => {
      return (
        !navItem.flag ||
        _.includes(featureFlags, navItem.flag) ||
        // TODO: remove once LaunchDarkly is sunset
        (_.has(ldFeatureFlags, navItem.flag) && ldFeatureFlags[navItem.flag])
      );
    },
    [featureFlags, ldFeatureFlags]
  );

  const entityTypeFilter = useCallback(
    (navItem) => {
      return !navItem.entityType || (navItem.entityType === entityType);
    },
    [entityType]
  );

  const MenuItemChildren = (children, isOpen) => {
    return (
      <Collapse in={isOpen} unmountOnExit>
        <Divider />
        {Object.keys(children)
          .filter((key) => permissionFilter(children[key]))
          .filter((key) => featureFlagFilter(children[key]))
          .map((key) => {
            const child = children[key];
            const IconElement = child.icon;
            return (
              <ListItem
                dense
                data-id={child.id}
                className={classes.navLink}
                component={NavLink}
                to={`${child.path}`}
                button
                key={key}
              >
                <ListItemIcon className={classes.subCategory}>
                  <FontAwesomeIcon
                    className={classes.navLink}
                    icon={IconElement}
                    size="lg"
                    key={key}
                  />
                </ListItemIcon>
                <ListItemText key={key} primary={child.text} />
              </ListItem>
            );
          })}
      </Collapse>
    );
  };

  return Object.keys(linksArray)
    .filter((key) => permissionFilter(linksArray[key]))
    .filter((key) => featureFlagFilter(linksArray[key]))
    .filter((key) => entityTypeFilter(linksArray[key]))
    .map((key) => {
      const { id, path, icon, text, children } = linksArray[key];
      const IconElement = icon;
      return (
        <React.Fragment key={key}>
          <ListItem
            data-id={id}
            data-expanded={children ? !!childrenState[text] : undefined}
            className={classes.navLink}
            component={path && path !== "" && NavLink}
            button={!path || path === ""}
            to={`${path}`}
            key={key}
            onClick={() => !!children && handleClick(text)}
          >
            <ListItemIcon>
              <FontAwesomeIcon
                className={classes.navLink}
                icon={IconElement}
                size="2x"
                key={key}
              />
            </ListItemIcon>
            <ListItemText key={key} primary={text} />
            <ListItemSecondaryAction className={classes.caretIcon}>
              {!!children && !childrenState[text] && (
                <IconButton onClick={() => handleClick(text)}>
                  <IconExpandMore />
                </IconButton>
              )}
              {!!children && childrenState[text] && (
                <IconButton onClick={() => handleClick(text)}>
                  <IconExpandLess />
                </IconButton>
              )}
            </ListItemSecondaryAction>
          </ListItem>
          {!!children && MenuItemChildren(children, childrenState[text])}
        </React.Fragment>
      );
    });
};

export default NavListItems;
