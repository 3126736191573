import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useStyles } from "./styles";
import { useHistory, useParams } from "react-router-dom";
import TaxesForm from "../Form";
import useTaxesContext from "../../../../hooks/useTaxesContext";
import { useEnqueueSnackbar } from "../../../../hooks/useEnqueueSnackbar";

const CreateEditTaxes = ({ onUpdate, taxesWrite }) => {
  const enqueueSnackbar = useEnqueueSnackbar();
  const history = useHistory();
  const { taxID } = useParams();
  const [tax, setTax] = useState({
    taxID,
    description: "",
    taxesToTaxRates: null
  });
  const { getTax } = useTaxesContext();

  useEffect(() => {
    if (taxID != -1) {
      getTaxesInfo();
    }
  }, []);

  const getTaxesInfo = () => {
    getTax(taxID)
      .then((res) => {
        if (res.status === 200) {
          setTax(res.data);
        } else {
          enqueueSnackbar(
            "We encountered a problem retrieving the tax information",
            { variant: "error", tag: "FailedToRetrieveTaxInfo", }
          );
        }
      })
      .catch(() => {
        enqueueSnackbar("Failed to retrieve tax info", { variant: "error", tag: "FailedToRetrieveTaxInfo", });
      });
  };

  const handleCancel = () => {
    history.goBack();
  };

  const handleOperationComplete = () => {
    onUpdate();
    history.goBack();
  };

  return (
    <TaxesForm
      data={tax}
      onCancel={handleCancel}
      onSubmitComplete={handleOperationComplete}
      onDeleteComplete={handleOperationComplete}
      taxesWrite={taxesWrite} 
    />
  );
};

CreateEditTaxes.defaultProps = {
  onUpdate: () => {},
};

CreateEditTaxes.propTypes = {
  onUpdate: PropTypes.func,
};

export default CreateEditTaxes;
