import React from "react";
import { useStyles } from "./styles";
import { Grid, TextField, Typography } from "@material-ui/core";
import {  Controller } from "react-hook-form";
import clsx from "clsx";
import MenuItem from "@material-ui/core/MenuItem";

const CreditCardTerminalSelection = ({
    availableCreditCardTerminals,
    currentSelectedTerminal,
    control,
    errors
  }) => {
  const classes = useStyles();
  return (
    <div className={clsx("card-content", classes.contentRoot)}>
      <Grid item xs={12} lg={6} className={clsx(classes.creditCardTerminalSelectionContainer)}>
        <Typography className={clsx("credit-card-terminal-selection")} variant="h5">
          Choose Your CC Terminal
        </Typography>
        <Controller
          name="creditcardterminal"
          control={control}
          render={({ field }) => (
          <TextField
            label="Credit Card Terminal"
            fullWidth
            {...field}
            defaultValue={currentSelectedTerminal}
            error={!!errors.creditcardterminal}
            helperText={errors.creditcardterminal && "Required"}
            id="creditcardterminal"
            className={clsx(
              "creditcardterminal",
              classes.creditCardTerminalDropdown
            )}
            select
            variant="outlined"
            SelectProps={{
              SelectDisplayProps: {
               "data-testid": "credit-card-terminal-selection",
               "data-options": availableCreditCardTerminals?.map(terminal => terminal.name),
               "aria-label": "cc-terminal-dropdown"
              },
            }}
            >
            {availableCreditCardTerminals?.map((terminal, index) => (
              <MenuItem 
                key={terminal.name} 
                value={terminal.value}
                className={clsx("terminal-option")}
                data-testid={`terminal-option-${index}`}
                data-name={terminal.name}
              >
                {terminal.name}
              </MenuItem>
            ))}
          </TextField>
        )}
      />
      </Grid>
    </div>
  );
};

export default CreditCardTerminalSelection;