import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  
  root: {
    padding: "20px"
  },
  rootContainer: {
    width: "80%",
  },
  input: {
    width: "100%",
  },
  deleteBtnGrid: {
    textAlign: "right",
  },
  btnContainer: {
    marginLeft: "0px",
  },
}));
