import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  tableRow: {
    borderBottom: `1px solid ${theme.palette.header}`,
  },
  buttonsTableCell: {
    borderBottom: "none",
    paddingRight: theme.spacing(1),
    justifyContent: "right",
    flexDirection: "column",
    alignItems: "right",
    marginRight: "10px",
    width: "250px",
    align: "left",
    [theme.breakpoints.down("md")]: {
      whiteSpace: "nowrap",
    },
  },
  customMarginLeft: {
    marginLeft: "20px",
  },
  tableContainer: {
    maxHeight: "69vh",
    overflowY: "auto"
  },
}));
