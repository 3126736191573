import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Grid, Button } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import { TextField } from "formik-material-ui";
import { Formik, Form, Field } from "formik";
import { useStyles } from "./styles";
import * as Yup from "yup";
import Title from "../../../Title";
import { isUndefined } from "lodash";
import useTaxRatesContext from "../../../../hooks/useTaxRatesContext";
import { useEnqueueSnackbar } from "../../../../hooks/useEnqueueSnackbar";
import useCurrentFacility from "../../../../hooks/useCurrentFacility";

const TaxRatesForm = ({
  data,
  onDeleteComplete,
  onSubmitComplete,
  onCancel,
  taxesWrite,
}) => {
  const [isCreateEditButtonDisabled, setIsCreateEditButtonDisabled] = useState(
    false
  );
  const classes = useStyles();
  const isEditTaxRate = !isUndefined(data.taxRateID) && data.taxRateID != -1;
  const { updateTaxRate, deleteTaxRates, createTaxRate } = useTaxRatesContext();
  const enqueueSnackbar = useEnqueueSnackbar();
  const { facilityID } = useCurrentFacility();

  const handleDelete = () => {
    deleteTaxRates(facilityID, [data.taxRateID])
      .then((res) => {
        if (res.status === 200) {
          var taxRateIDsNotDeleted = res.data;
          let found = taxRateIDsNotDeleted.find((x) => x == data.taxRateID);
          if (found) {
            const message = "Tax rate in use. Cannot delete";
            enqueueSnackbar(message, {
              variant: "warning",
            });
          } else {
            enqueueSnackbar("Successfully deleted tax rate", {
              variant: "success",
            });
            onDeleteComplete();
          }
        } else if (res.status === 400) {
          enqueueSnackbar(`Unable to delete tax rate: ${res.data}`, {
            variant: "error", tag: "UnableToDeleteTaxRate"
          });
        } else {
          enqueueSnackbar(
            "We encountered a problem while deleting the tax rate",
            {
              variant: "error", tag: "ErrorDeletingTaxRate"
            }
          );
        }
      })
      .catch((err) => {
        enqueueSnackbar("Failed to delete tax rate", {
          variant: "error", tag: "FailedToDeleteTaxRate" 
        });
      });
  };

  const createOrUpdateTaxRate = async (values) => {
    setIsCreateEditButtonDisabled(true);
    const { description, taxAmount } = values;
    const taxRate = { description, taxAmount };
    taxRate.entityID = facilityID;
    if (isEditTaxRate) {
      taxRate.taxRateID = data.taxRateID;
      updateTaxRate(facilityID, taxRate)
        .then((res) => {
          if (res.status === 200) {
            enqueueSnackbar("Successfully updated tax rate", {
              variant: "success",
            });
            onSubmitComplete();
          } else {
            enqueueSnackbar("We encountered a problem updating the tax rate", {
              variant: "error", tag: "FailedToUpdateTaxRate"
            });
          }
        })
        .catch(() => {
          enqueueSnackbar("Failed to update tax rate", {
            variant: "error", tag: "FailedToUpdateTaxRate"
          });
        });
    } else {
      createTaxRate(facilityID, taxRate)
        .then((res) => {
          if (res.status === 200) {
            enqueueSnackbar("Successfully created tax rate", {
              variant: "success",
            });
            onSubmitComplete();
          } else {
            enqueueSnackbar("We encountered a problem creating the tax rate", {
              variant: "error", tag: "ErrorCreatingTheTaxRate"
            });
          }
        })
        .catch((err) => {
          enqueueSnackbar("Failed to create tax rate", {
            variant: "error", tag: "FailedToCreateTaxRate"
          });
        });
    }
  };

  const TaxRateSchema = Yup.object().shape({
    description: Yup.string().required("Description Required"),
    taxAmount: Yup.number()
      .required("Tax Amount Required")
      .positive("Must be greater than zero")
      .test(
        "len",
        "Must be 17 digits or less",
        (val) => val.toString().length <= 17
      ),
  });

  return (
    <Box className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <Title>{isEditTaxRate === true ? "Edit " : "Create "} Tax Rate</Title>
        </Grid>
        {isEditTaxRate === true && taxesWrite && (
          <Grid item xs={3} className={classes.deleteBtnGrid}>
            <Button
              startIcon={<DeleteIcon />}
              variant="contained"
              color="secondary"
              onClick={handleDelete}
            >
              Delete
            </Button>
          </Grid>
        )}
      </Grid>
      <Formik
        onSubmit={async (values) => {
          await createOrUpdateTaxRate(values);
        }}
        enableReinitialize
        validateOnChange={false}
        initialValues={data}
        validationSchema={TaxRateSchema}
      >
        {({ submitForm, setFieldValue }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Field
                  disabled={!taxesWrite}
                  component={TextField}
                  className={classes.input}
                  variant="outlined"
                  name="description"
                  label="Tax Rate Description"
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  disabled={!taxesWrite}
                  component={TextField}
                  className={classes.input}
                  variant="outlined"
                  name="taxAmount"
                  label="Tax Rate Percentage"
                />
              </Grid>
              <Grid className={classes.btnContainer} container spacing={2}>
                {taxesWrite && (
                  <Grid item>
                    <Button
                      disabled={isCreateEditButtonDisabled}
                      startIcon={<SaveIcon />}
                      variant="contained"
                      color="primary"
                      onClick={submitForm}
                    >
                      {isEditTaxRate === true ? "Update" : "Create"}
                    </Button>
                  </Grid>
                )}
                <Grid item>
                  <Button
                    startIcon={<CancelIcon />}
                    variant="contained"
                    onClick={onCancel}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

TaxRatesForm.defaultProps = {
  data: {},
  onSubmitComplete: () => {},
  onDeleteComplete: () => {},
  onCancel: () => {},
  onMessage: () => {},
};

TaxRatesForm.propTypes = {
  data: PropTypes.shape({
    taxRateID: PropTypes.any,
    Description: PropTypes.string,
    taxAmount: PropTypes.number,
    entityID: PropTypes.string,
  }),
  onSubmitComplete: PropTypes.func,
  onDeleteComplete: PropTypes.func,
  onCancel: PropTypes.func,
  onMessage: PropTypes.func,
};

export default TaxRatesForm;
