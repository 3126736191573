import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
  },
  displaySettingContainer: {
    textAlign: "center",
    width: "100%",
  },
  settingsTreeContainer: {
    maxWidth: "25%",
  },
  settingListContainer: {
    minWidth: "200px",
  },
}));
