import React, { useCallback, useEffect } from "react";
import { Grid } from "@material-ui/core";
import SettingsList from "../SettingsList";
import SettingDisplay from "../SettingDisplay";
import { useStyles } from "./styles";
import Alert from "../../Alert";
import useSettingsContext from "../../../hooks/useSettingsContext";
import Title from "../../Title";
import useCurrentFacility from "../../../hooks/useCurrentFacility";

const SettingsContainer = () => {
  const classes = useStyles();
  const { facilityID } = useCurrentFacility();
  const [alert, setAlert] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [settings, setSettings] = React.useState([]);
  const { getSettings } = useSettingsContext();
  const [selectedSetting, setSelectedSetting] = React.useState();

  const handleSettingSelected = (setting) => {
    setSelectedSetting(setting);
  };

  const handleSettingSaved = (setting) => {
    let foundSetting = settings.find(
      (x) => x.settingName === setting.settingName
    );
    if (foundSetting) {
      foundSetting.settingValue = setting.settingValue;
      setSettings(settings);
    }
  };

  const fillSettingsTree = useCallback(
    (facilityID) => {
      getSettings(facilityID, true)
        .then((result) => {
          if (result.status === 200) {
            setSettings(
              result.data.sort((x, y) => {
                if (x?.settingName < y?.settingName) return -1;
                else if (x?.settingName > y?.settingName) return 1;
                else return 0;
              })
            );
          } else {
            setAlert({
              isOpen: true,
              message: "Failed to retrieve settings",
              type: "error",
            });
          }
        })
        .catch(() => {
          setAlert({
            isOpen: true,
            message: "Failed to retrieve settings",
            type: "error",
          });
        });
    },
    [getSettings, setAlert]
  );

  const handleAlertClose = (event, reason) => {
    setAlert({ isOpen: false });
  };

  useEffect(() => {
    fillSettingsTree(facilityID);
  }, [fillSettingsTree, facilityID]);

  return (
    <>
      <Grid container>
        <Grid item xs={2} lg={2} className={classes.settingListContainer}>
          <Title>Settings</Title>
          <SettingsList
            settings={settings}
            onItemClick={handleSettingSelected}
          />
        </Grid>
        <Grid item xs={9} lg={9} className={classes.displaySettingContainer}>
          <SettingDisplay
            setting={selectedSetting}
            setAlert={setAlert}
            onSettingSaved={handleSettingSaved}
          />
        </Grid>
      </Grid>
      <Alert open={alert.isOpen} type={alert.type} onClose={handleAlertClose}>
        {alert.message}
      </Alert>
    </>
  );
};

export default SettingsContainer;
