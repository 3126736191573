import { makeStyles } from "@material-ui/core/styles";

//(╯°□°)╯︵ ┻━┻
export const useStyles = makeStyles((theme) => ({
  root: {
    "&:hover, &:focus": {
      backgroundColor: (props) => theme.palette[props.modes[props.mode]]?.light,
      color: "#fff",
    },
    backgroundColor: (props) =>
      props.mode === "unselected"
        ? "lightGrey"
        : theme.palette[props.modes[props.mode]]?.light,
    color: "#fff",
  },

  square: {
    borderRadius: 3,
  },
}));
