import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { Box, Grid, Button } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import { TextField } from "formik-material-ui";
import { Formik, Form, Field } from "formik";
import { useStyles } from "./styles";
import * as Yup from "yup";
import Title from "../../../Title";
import { isUndefined } from "lodash";
import useTaxesContext from "../../../../hooks/useTaxesContext";
import { useEnqueueSnackbar } from "../../../../hooks/useEnqueueSnackbar";
import TaxRatesPanel from "../../../Panels/TaxRates";
import TaxSettingsPanel from "../../../Panels/TaxSettings";
import * as _ from "lodash";
import useCurrentFacility from "../../../../hooks/useCurrentFacility";

const TaxesForm = ({
  data,
  onDeleteComplete,
  onSubmitComplete,
  onCancel,
  taxesWrite
}) => {
  const [isCreateEditButtonDisabled, setIsCreateEditButtonDisabled] = useState(
    false
  );
  const classes = useStyles();
  const isEditTax = !isUndefined(data.taxID) && data.taxID != -1;
  const { updateTax, deleteTaxes, createTax } = useTaxesContext();
  const enqueueSnackbar = useEnqueueSnackbar();
  const { facilityID } = useCurrentFacility();

  const handleDelete = () => {
    deleteTaxes(facilityID, [data.taxID])
      .then((res) => {
        if (res.status === 200) {
          var taxIDsNotDeleted = res.data;
          let found = taxIDsNotDeleted.find((x) => x == data.taxID);
          if (found) {
            const message = "Tax in use. Cannot delete";
            enqueueSnackbar(message, {
              variant: "warning", tag: "WarningTaxInUse"
            });
          } else {
            enqueueSnackbar("Successfully deleted tax", {
              variant: "success",
            });
            onDeleteComplete();
          }
        } else if (res.status === 400) {
          enqueueSnackbar(`Unable to delete tax: ${res.data}`, {
            variant: "error", tag: "UnableTODeleteTax"
          });
        } else {
          enqueueSnackbar("We encountered a problem while deleting the tax", {
            variant: "error", tag: "ErrorDeletingTheTax"
          });
        }
      })
      .catch((err) => {
        enqueueSnackbar("Failed to delete tax", {
          variant: "error", tag: "FailedToDeleteTax"
        });
      });
  };

  const createOrUpdateTax = async (values) => {
    setIsCreateEditButtonDisabled(true);
    const { description } = values;
    const tax = { description };

    tax.taxRates = [];
    if (null != values.taxRates) {
      values.taxRates.forEach((element) => {
        tax.taxRates.push(element);
      });
    }
    tax.taxSettings = [];
    if (null != values.taxSettings) {
      values.taxSettings.forEach((element) => {
        tax.taxSettings.push(element);
      });
    }
    tax.entityID = facilityID;
    if (isEditTax) {
      tax.taxID = data.taxID;
      updateTax(facilityID, tax)
        .then((res) => {
          if (res.status === 200) {
            enqueueSnackbar("Successfully updated tax", {
              variant: "success",
            });
            onSubmitComplete();
          } else {
            enqueueSnackbar("We encountered a problem updating the tax", {
              variant: "error", tag: "ErrorUpdatingTax"
            });
          }
        })
        .catch((error) => {
          enqueueSnackbar("Failed to update tax", {
            variant: "error", tag: "FailedToUpdateTax" 
          });
        });
    } else {
      createTax(facilityID, tax)
        .then((res) => {
          if (res.status === 200) {
            enqueueSnackbar("Successfully created tax", {
              variant: "success",
            });
            onSubmitComplete();
          } else {
            enqueueSnackbar("We encountered a problem creating the tax", {
              variant: "error", tag: "ErrorCreatingTheTax"
            });
          }
        })
        .catch((err) => {
          enqueueSnackbar("Failed to create tax", {
            variant: "error", tag: "FailedToCreateTax"
          });
        });
    }
  };

  const TaxRateSchema = Yup.object().shape({
    description: Yup.string().required("Description Required"),
    taxRateIDs: Yup.array(Yup.number()),
  });

  return (
    <Box className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <Title>{isEditTax === true ? "Edit " : "Create "} Tax</Title>
        </Grid>
        {isEditTax === true && taxesWrite && (
          <Grid item xs={3} className={classes.deleteBtnGrid}>
            <Button
              startIcon={<DeleteIcon />}
              variant="contained"
              color="secondary"
              onClick={handleDelete}
            >
              Delete
            </Button>
          </Grid>
        )}
      </Grid>
      <Formik
        onSubmit={async (values) => {
          await createOrUpdateTax(values);
        }}
        enableReinitialize
        validateOnChange={false}
        initialValues={data}
        validationSchema={TaxRateSchema}
      >
        {({ submitForm, setFieldValue }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Field
                  disabled={!taxesWrite}
                  component={TextField}
                  className={classes.input}
                  variant="outlined"
                  name="description"
                  label="Tax Description"
                />
              </Grid>
              <Grid item xs={4}>
                <TaxRatesPanel
                  taxesWrite={taxesWrite}
                  selected={data.taxRates}
                  onChange={(taxRates) => {
                    setFieldValue("taxRates", taxRates);
                  }}
                />
              </Grid>
              <Grid item xs={8}>
                <TaxSettingsPanel
                  taxesWrite={taxesWrite}
                  selected={data.taxSettings}
                  onChange={(taxSettings) => {
                    setFieldValue("taxSettings", taxSettings);
                  }}
                />
              </Grid>
            </Grid>
            <Grid className={classes.btnContainer} container spacing={2}>
              {taxesWrite && (
                <Grid item>
                  <Button
                    disabled={isCreateEditButtonDisabled}
                    startIcon={<SaveIcon />}
                    variant="contained"
                    color="primary"
                    onClick={submitForm}
                  >
                    {isEditTax === true ? "Update" : "Create"}
                  </Button>
                </Grid>
              )}
              <Grid item>
                <Button
                  startIcon={<CancelIcon />}
                  variant="contained"
                  onClick={onCancel}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

TaxesForm.defaultProps = {
  data: {},
  onSubmitComplete: () => {},
  onDeleteComplete: () => {},
  onCancel: () => {},
  onMessage: () => {},
};

TaxesForm.propTypes = {
  data: PropTypes.shape({
    taxID: PropTypes.any,
    Description: PropTypes.string,
    entityID: PropTypes.string,
  }),
  onSubmitComplete: PropTypes.func,
  onDeleteComplete: PropTypes.func,
  onCancel: PropTypes.func,
  onMessage: PropTypes.func,
};

export default TaxesForm;
