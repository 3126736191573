import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
    Typography,
    Button,
    Table,
    TableHead,
    TableCell,
    TableRow,
    TableBody,
    Checkbox,
    TableContainer,
    IconButton,
} from "@material-ui/core";
import { Reorder } from "@material-ui/icons";
import { useStyles } from "./styles";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

//"isEditable" variable is used to check whether Ticket fields can be editable or not.
//(Valet Area, Facility Group Under Tenant ->Editable)
//(Valet Area under Facilty Group -> View Only).
const TicketFieldList = ({
    ticketFieldArray,
    handleDeleteButton,
    handleRequiredField,
    isEditable,
    requestName,
    onHandleDrag,
}) => {
    const classes = useStyles();
    const [ticketFields, setTicketFields] = useState([]);
    const [loadingRows, setLoadingRows] = useState({});

    useEffect(() => {
        setTicketFields([...ticketFieldArray]);
    }, [ticketFieldArray]);

    const handleCheckBox = async (ticketfield) => {
        setLoadingRows((prevState) => ({ ...prevState, [ticketfield.fieldID]: true }));
        ticketfield.required = !ticketfield.required;

        try {
            await handleRequiredField(ticketfield);
        } finally {
            setLoadingRows((prevState) => ({ ...prevState, [ticketfield.fieldID]: false }));
        }
    };

    const handleRemoveButton = async (ticketfield) => {
        setLoadingRows((prevState) => ({ ...prevState, [ticketfield.fieldID]: true }));

        try {          
            await handleDeleteButton(ticketfield.fieldID);
        } finally {            
                setLoadingRows((prevState) => ({ ...prevState, [ticketfield.fieldID]: false }));
        }
    };

    return (        
        <DragDropContext onDragEnd={onHandleDrag}>
            <TableContainer className={clsx(classes.tableContainer)}>
                <Table className={clsx("table")}>                    
                    {ticketFields?.length <= 0 ? (
                        <Typography className={clsx("no-data-message", classes.customMarginLeft)} variant="body1">
                            {isEditable ? 'There is no data to display.' : `No ${requestName} Ticket Fields have been configured in the facility group level`}
                        </Typography>
                    ) : (
                        <>
                        <TableHead> 
                            <TableRow className={clsx(classes.tableRow)}> 
                                <TableCell className={clsx(classes.buttonsTableCell)}></TableCell>                                                                                        
                                <TableCell>Required</TableCell>                                                                                       
                            </TableRow>                                                                                
                        </TableHead>                        
                        <Droppable droppableId="ticketFields">
                            {(provided) => (                                       
                                <TableBody
                                    className={clsx("table-body")}
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}                                    
                                >
                                       {ticketFields.map((row, index) => (
                                        <Draggable key={row.id || index} draggableId={(row.id || index).toString()} index={index}>
                                            {(provided) => (
                                                <TableRow
                                                    key={row.id}
                                                    className={clsx(classes.tableRow)}
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                >
                                                    <TableCell className={clsx(classes.buttonsTableCell)}>
                                                        <Typography>{row.fieldName}</Typography>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {isEditable ? (
                                                            <>
                                                                <Checkbox
                                                                    data-testid="requiredChBox"
                                                                    color="primary"
                                                                    checked={row.required}
                                                                    onChange={() => handleCheckBox(row)}
                                                                    disabled={loadingRows[row.fieldID]}
                                                                    style={{ marginLeft: "10px" }}
                                                                />
                                                                <Button
                                                                    data-testid="removeBtn"
                                                                    variant="contained"
                                                                    color="secondary"
                                                                    className={clsx("remove-button")}
                                                                    onClick={() => handleRemoveButton(row)}
                                                                    disabled={loadingRows[row.fieldID]}
                                                                    style={{ marginLeft: "100px" }}
                                                                >
                                                                    Remove
                                                                </Button>
                                                                <IconButton {...provided.dragHandleProps}
                                                                data-testid="draggable-item"
                                                                >
                                                                    <Reorder />
                                                                </IconButton>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Checkbox
                                                                    data-testid="requiredChBox"
                                                                    color="primary"
                                                                    checked={row.required}
                                                                    disabled={true}
                                                                    style={{ marginRight: "10px" }}
                                                                />
                                                                <IconButton disabled>
                                                                    <Reorder />
                                                                </IconButton>
                                                            </>
                                                        )}
                                                 </TableCell>
                                                </TableRow>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </TableBody>
                            )}
                        </Droppable>
                        </>
                    )}
                </Table>
            </TableContainer>
        </DragDropContext>
    );
};

TicketFieldList.defaultProps = {
    ticketFieldArray: [],
    handleDeleteButton: () => { },
    handleRequiredField: () => { },
    isEditable: true,
};

TicketFieldList.propTypes = {
    ticketFieldArray: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            fieldName: PropTypes.string.isRequired,
            required: PropTypes.bool.isRequired,
        })
    ).isRequired,
    handleDeleteButton: PropTypes.func,
    handleRequiredField: PropTypes.func,
    isEditable: PropTypes.bool,
    requestName: PropTypes.string.isRequired,
    onHandleDrag: PropTypes.func
};


export default TicketFieldList;
