import React, { useState, useEffect } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import PageableEntity, { PaginatorLocations } from "../../../PageableEntity";
import { useEnqueueSnackbar } from "../../../../hooks/useEnqueueSnackbar";
import useTaxesContext from "../../../../hooks/useTaxesContext";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import CreateEditTaxes from "../CreateEditTaxes";
import { Grid, Box } from "@material-ui/core";
import useHasPermissions from "../../../../hooks/useHasPermissions";
import { onTaxFilterChange } from "../../../../reducers/taxes/taxReducer";
import useCurrentFacility from "../../../../hooks/useCurrentFacility";

function getRowsByHeight(height) {
  return Math.round(height / 200);
}

const TaxesManagementContainer = () => {
  const enqueueSnackbar = useEnqueueSnackbar();
  const { facilityID } = useCurrentFacility();
  const history = useHistory();
  const [taxes, setTaxes] = useState({
    totalCount: 0,
    collection: [],
    display: []
  });
  const { deleteTaxes, getPageableTaxes, taxReducer } = useTaxesContext();
  const { height } = useWindowDimensions();
  const { hasPermissions } = useHasPermissions();
  const taxesWrite = hasPermissions(["taxes.write"]);
  const [itemLimit, setItemLimit] = useState(getRowsByHeight(height));
  const [taxData, taxDispatch] = taxReducer;

  useEffect(() => {
    if (height) {
      setItemLimit(height > 0 ? getRowsByHeight(height) : 0);
    }
  }, [height]);

  useEffect(() => {
    updateTaxesSet(taxData.taxPage, itemLimit, taxData.taxSearchTerm);
  }, [facilityID, itemLimit, taxData.taxPage, taxData.taxSearchTerm]);

  const updateTaxesSet = (page, limit = itemLimit, searchTerm = "") => {
    getPageableTaxes(
      facilityID,
      limit,
      page ? (page - 1) * limit : 0,
      searchTerm
    )
      .then(res => {
        if (res.status === 200) {
          setTaxes(res.data);
          formatTaxesData(res.data.collection);
        } else {
          enqueueSnackbar("We encountered a problem retrieving taxes", {
            variant: "error",
            tag: "ErrorRetrievingTaxes"
          });
        }
      })
      .catch(() => {
        enqueueSnackbar("Failed to retrieve taxes", {
          variant: "error",
          tag: "FailedToRetrieveTaxes"
        });
      });
  };

  const formatTaxesData = rawTaxes => {
    const formatted = rawTaxes.map(tax => {
      return {
        id: tax.taxID,
        displayContent: (
          <Grid container spacing={2}>
            <Grid item container direction="column">
              <Grid item>
                <Box fontWeight="bold" fontSize="large">
                  {tax.description}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        )
      };
    });

    setTaxes(prev => ({ ...prev, display: formatted }));
  };

  const handleAddTax = () => {
    history.push(`/taxmgmt/taxes/-1`);
  };

  const handleTaxSelected = taxID => {
    history.push(`/taxmgmt/taxes/${taxID}`);
  };

  const handleTaxSearch = searchTerm => {
    taxDispatch({
      type: onTaxFilterChange,
      payload: { taxSearchTerm: searchTerm, taxPage: 1 }
    });
  };

  const handleDeleteTaxes = taxIDs => {
    if (taxIDs?.length === 0) {
      return;
    }

    deleteTaxes(facilityID, taxIDs)
      .then(res => {
        if (res.status === 200) {
          var taxRateIDsNotDeleted = res.data;
          if (taxRateIDsNotDeleted.length > 0) {
            const message = `Some taxes could not be deleted. ${taxIDs.length -
              taxRateIDsNotDeleted.length} of ${
              taxIDs.length
            } taxes where deleted`;
            enqueueSnackbar(message, {
              variant: "warning"
            });
          } else {
            const message = `Successfully deleted ${taxIDs.length -
              taxRateIDsNotDeleted.length} tax${taxIDs.length > 1 ? `s` : ``}`;
            enqueueSnackbar(message, {
              variant: "success"
            });
          }
          refreshList();
        }
      })
      .catch(error => {
        enqueueSnackbar("Error deleting taxes", {
          variant: "error",
          tag: "ErrorDeletingTaxes"
        });
      });
  };

  const handlePageChange = (e, page) => {
    taxDispatch({
      type: onTaxFilterChange,
      payload: { taxPage: page }
    });
  };

  const refreshList = () => {
    updateTaxesSet(taxData.taxPage, itemLimit, taxData.taxSearchTerm);
  };

  return (
    <Switch>
      <Route exact path="/taxmgmt/taxes">
        <PageableEntity
          dataID="taxes-pageable"
          title="Tax Management"
          searchBarLabel="Search taxes by name"
          addBtnLabel="Add Tax"
          addBtnClicked={handleAddTax}
          items={taxes.display}
          onItemClicked={handleTaxSelected}
          onSearchChange={handleTaxSearch}
          totalCount={Math.ceil(taxes.totalCount / itemLimit)}
          onPageChange={handlePageChange}
          searchDebounce={100}
          deleteBtnClicked={handleDeleteTaxes}
          hideDeleteBtn={!taxesWrite}
          hideAddBtn={!taxesWrite}
          currentPage={taxData.taxPage ?? 1}
          defaultSearch={taxData.taxSearchTerm}
          paginatorLocation={PaginatorLocations.TOP}
        />
      </Route>
      <Route exact path="/taxmgmt/taxes/:taxID">
        <CreateEditTaxes onUpdate={refreshList} taxesWrite={taxesWrite} />
      </Route>
    </Switch>
  );
};

export default TaxesManagementContainer;
