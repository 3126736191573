import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";

/* Jonathan Payares 07/26/2024 
  This wrapper component should be used with caution. if nested incorrectly, it will cause a lot of unwanted styles and errors to be applied to the title.
  I advise to not use this component and just use the default Material UI Typography component.
*/
export default function Title({children, noGutter, cssSelector }) {
  return (
    <Typography
      component="h2"
      variant="h4"
      color="primary"
      className={clsx(`${cssSelector}`)}
      gutterBottom={!noGutter}
    >
      {children}
    </Typography>
  );
}

Title.propTypes = {
  children: PropTypes.node,
  noGutter: PropTypes.bool,
  cssSelector: PropTypes.string
};

Title.defaultProps = {
  cssSelector: ""
}