import React from "react";
import moment from "moment-timezone";
import { useForm, Controller } from "react-hook-form";
import { DateTimePicker } from "@material-ui/pickers";
import DoneIcon from "@material-ui/icons/Done";
import CancelIcon from "@material-ui/icons/Cancel";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";

const DisplayFormat = "MM/DD/YYYY  h:mm a";
const DisplayMask = "__/__/____  __:__ _M";
const InternalFormat = "YYYY-MM-DDTHH:mm:ssZ";

const EditTicketForm = (props) => {
  const { handleSubmit, control } = useForm();
  const { currentTicket, onTicketSubmit, endEditing } = props;

  const handleUpdateTicket = (values) => {
    if (values.endTime === undefined) {
      endEditing();
      return;
    }

    const expiredDate = moment(values.endTime).format(InternalFormat);

    const newTicket = {
      ...currentTicket,
      expiredDate,
      status: "void",
      activityDate: moment(Date.now()).format(InternalFormat),
    };
    onTicketSubmit(newTicket);
    endEditing();
  };

  const initialEndDate = currentTicket.expiredDate
    ? moment.utc(currentTicket.expiredDate).local()
    : moment(Date.now());

  return (
    <form
      data-id="edit-ticket-form"
      onSubmit={handleSubmit(handleUpdateTicket)}
    >
      <Box display="flex" alignItems="center">
        <Controller
          control={control}
          name="endTime"
          render={({ field }) => <DateTimePicker
            {...field}
            autoOk
            initialFocusedDate={initialEndDate}
            error={false}
            label="End Time"
            invalidDateMessage=""
            inputVariant="outlined"
            margin="normal"
            format={DisplayFormat}
            mask={DisplayMask}
          />}
        />
        <IconButton data-id="submit-button" type="submit">
          <DoneIcon />
        </IconButton>
        <IconButton
          data-id="cancel-button"
          onClick={endEditing}
          name="cancel button"
        >
          <CancelIcon />
        </IconButton>
      </Box>
    </form>
  );
};

export default EditTicketForm;
