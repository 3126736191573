import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {},
  treeContainer: {
    overflowY: "auto",
    maxHeight: "100%",
  },
  treeItem: {
    marginTop: theme.spacing(1),
  },
}));
