import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useStyles } from "./styles";
import Box from "@material-ui/core/Box";
import Title from "../../../Title";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import useApplyTaxesContext from "../../../../hooks/useApplyTaxesContext";
import { Formik, Form } from "formik";
import { useEnqueueSnackbar } from "../../../../hooks/useEnqueueSnackbar";
import DeleteIcon from "@material-ui/icons/Delete";
import TaxesPanel from "../../../Panels/Taxes";
import TaxesProvider from "../../../../providers/TaxesProvider";
import ApplyTaxesProvider from "../../../../providers/ApplyTaxesProvider";
import { isUndefined } from "lodash";
import useHasPermissions from "../../../../hooks/useHasPermissions";

const ApplyTaxesContainer = props => {
  return (
    <ApplyTaxesProvider>
      <ApplyTaxesEditor entityid={props.entityid} onClose={props.onClose} />
    </ApplyTaxesProvider>
  );
};

export const ApplyTaxesEditor = ({ entityid, onClose }) => {
  const enqueueSnackbar = useEnqueueSnackbar();
  const classes = useStyles();
  const {
    applyTaxesToEntity,
    getAppliedTaxesForEntity,
    deleteAppliedTaxesForEntity
  } = useApplyTaxesContext();
  const [isLoading, setIsLoading] = useState(true);
  const [taxIDs, setTaxIDs] = useState([]);
  const [taxIDsToApply, setTaxIDsToApply] = useState([]);
  const entityID = entityid;
  const { hasPermissions } = useHasPermissions();
  const EditTaxesPermission = hasPermissions(["taxes.edit"]);
  const DeleteTaxesPermission = hasPermissions(["taxes.delete"]);

  const populateEntityTaxes = useCallback(() => {
    getAppliedTaxesForEntity(entityID)
      .then(result => {
        if (result?.status === 200) {
          let taxIDs = [];
          result.data.forEach(element => {
            taxIDs.push(element.taxID);
          });
          setTaxIDs(taxIDs);
        }
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
        enqueueSnackbar("Unable to get applied taxes for this entity", {
          variant: "error",
          tag: "UnableToGetAppliedTaxesForEntity"
        });
      });
  }, [getAppliedTaxesForEntity]);

  async function updateAppliedTaxes(values) {
    setIsLoading(true);
    let tempTaxIDS = [];
    if (isUndefined(taxIDsToApply)) {
      tempTaxIDS = [...taxIDs];
    } else {
      tempTaxIDS = [...taxIDsToApply];
    }
    return await applyTaxesToEntity(values, tempTaxIDS)
      .then(result => {
        if (result?.status === 200) {
          enqueueSnackbar("Taxes applied!", { variant: "success" });
          onClose();
        } else {
          enqueueSnackbar("Encountered a problem while applying taxes", {
            variant: "error",
            tag: "ErrorWhileApplyingTaxes"
          });
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        enqueueSnackbar("Unable to save applied taxes", {
          variant: "error",
          tag: "UnableToSaveAppliedTaxes"
        });
      });
  }

  useEffect(() => {
    populateEntityTaxes();
  }, [populateEntityTaxes]);

  async function handleDelete() {
    setIsLoading(true);
    return await deleteAppliedTaxesForEntity(entityID)
      .then(result => {
        if (result?.status === 200) {
          if ("string" == typeof result.data) {
            enqueueSnackbar(result.data, {
              variant: "warning"
            });
          } else if (result.data == 0) {
            enqueueSnackbar("No applied taxes were deleted!", {
              variant: "warning",
              tag: "NoAppliedTaxesWereDeleted"
            });
          } else {
            enqueueSnackbar("Deleted applies taxes!", { variant: "success" });
            onClose();
          }
        } else {
          enqueueSnackbar(
            "Encountered a problem while deleting applied taxes",
            {
              variant: "error",
              tag: "ErrorDeletingAppliedTaxes"
            }
          );
        }
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
        enqueueSnackbar("Unable to delete applied taxes", {
          variant: "error",
          tag: "UnableToDeleteAppliedTaxes"
        });
      });
  }

  const handleTaxID = ids => {
    setTaxIDsToApply(ids);
  };

  return (
    <TaxesProvider>
      <Box className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <Title>Apply Taxes</Title>
          </Grid>
          {DeleteTaxesPermission && (
            <Grid item xs={3} className={classes.deleteBtnGrid}>
              <Button
                startIcon={<DeleteIcon />}
                variant="contained"
                color="secondary"
                disabled={isLoading}
                data-id="applyTaxesEditorDelete"
                onClick={handleDelete}
              >
                Delete
              </Button>
            </Grid>
          )}
        </Grid>
        <Formik
          enableReinitialize
          initialValues={entityID}
          onSubmit={async values => {
            await updateAppliedTaxes(values);
          }}
          validateOnChange={false}
        >
          {({ submitForm, setFieldValue }) => (
            <Form>
              <Grid container spacing={1}>
                <TaxesPanel
                  className={classes.input}
                  selected={taxIDs}
                  onChange={taxID => {
                    handleTaxID(taxID);
                  }}
                  disabled={!EditTaxesPermission}
                />
                <Grid className={classes.btnContainer} container spacing={2}>
                  <>
                    <Grid item xs={12} style={{ textAlign: "right" }}>
                      {EditTaxesPermission && (
                        <Button
                          style={{ marginRight: 10 }}
                          data-id="save-button"
                          startIcon={<SaveIcon />}
                          variant="contained"
                          color="primary"
                          disabled={isLoading}
                          onClick={submitForm}
                        >
                          Save
                        </Button>
                      )}
                      <Button
                        data-id="cancel-button"
                        startIcon={<CancelIcon />}
                        variant="contained"
                        onClick={onClose}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </TaxesProvider>
  );
};

ApplyTaxesEditor.defaultProps = {
  entityid: "",
  onClose: () => {}
};

ApplyTaxesEditor.propTypes = {
  onClose: PropTypes.func,
  entityid: PropTypes.string
};

export default ApplyTaxesContainer;
