import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import { List, ListItem, ListItemText } from "@material-ui/core";
import SearchBar from "../../SearchBar";
import { useStyles } from "./styles";

const SettingsList = ({ settings, onItemClick, width }) => {
  const classes = useStyles();
  const prevSettings = settings;
  const [settingData, setSettingData] = React.useState({
    settings: [],
    renderSettings: [],
  });

  const handleSearchChange = useCallback(
    (val) => {
      const tmpSettings = settingData.settings
        ?.filter((x) =>
          x?.settingName?.toLowerCase().includes(val?.toLowerCase())
        )
        .sort((x, y) => {
          if (x?.settingName < y?.settingName) return -1;
          else if (x?.settingName > y?.settingName) return 1;
          else return 0;
        });

      setSettingData((prev) => ({
        ...prev,
        renderSettings: tmpSettings,
      }));
    },
    [settingData.settings]
  );

  useEffect(() => {
    if (!_.isEqual(prevSettings.current, settings)) {
      setSettingData({ settings, renderSettings: settings });
      prevSettings.current = settings;
    }
  }, [prevSettings, settings]);

  return (
    <div className={classes.treeContainer} style={{ width }}>
      <SearchBar onChange={handleSearchChange} delay={0} />
      <List>
        {settingData.settings &&
          settingData.renderSettings?.map((setting, index) => {
            return (
              <ListItem
                key={index}
                button
                onClick={() =>
                  onItemClick({
                    settingName: setting.settingName,
                    settingValue: setting.settingValue,
                    validationType: setting.validationType,
                  })
                }
              >
                <ListItemText primary={setting.settingName} />
              </ListItem>
            );
          })}
      </List>
    </div>
  );
};

SettingsList.defaultProps = {
  settings: [],
  onItemClick: () => {},
  width: "100%",
};

SettingsList.propTypes = {
  settings: PropTypes.arrayOf(
    PropTypes.shape({
      settingName: PropTypes.string,
      settingValue: PropTypes.string,
      validationType: PropTypes.string,
    })
  ),
  onItemClick: PropTypes.func,
  width: PropTypes.string,
};

export default SettingsList;
